var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex"}},[_c('v-combobox',{staticClass:"to-upper",class:_vm.blocked ? 'blockedclient': '',style:(_vm.showDenomination ? 'width: 80%; max-width: 80%;' : ''),attrs:{"autocomplete":_vm.autocompleteValueLocal,"items":_vm.clients,"item-value":"id","item-text":"name","label":_vm.$t(_vm.label),"search-input":_vm.search_client,"no-filter":"","return-object":"","outlined":_vm.is_boxes,"dense":_vm.is_boxes,"error-messages":_vm.errorMessages,"disabled":_vm.disabled},on:{"change":function($event){return _vm.removeMember()},"keydown":[function($event){return _vm.handleInput($event, 'alphanumeric', 50)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();}],"update:searchInput":function($event){_vm.search_client=$event},"update:search-input":function($event){_vm.search_client=$event}},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.required)?_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t(_vm.label))+" ")]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('p',{staticClass:"client_name"},[_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.first_name)+" "),(item.company && item.company.tva)?[_vm._v(" - "+_vm._s(item.company.tva)+" ")]:_vm._e()],2),_c('p',{staticClass:"client_detail"},[[(item.representative_company_id)?_c('span',[_vm._v(_vm._s(_vm.$t('entity_type.company')))]):_c('span',[_vm._v(_vm._s(_vm.$t('entity_type.private')))])],(item.email)?[_vm._v(" - "+_vm._s(item.email)+" ")]:_vm._e(),(item.phone)?[_vm._v(" - "+_vm._s(item.phone)+" ")]:_vm._e(),(item.company_address && item.company_address.length > 0)?[_c('br'),_vm._v(" "+_vm._s(_vm.computeAddressLabel(item.company_address[0].address))+" ")]:_vm._e()],2)])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',{class:_vm.blocked ? 'blockedtext' : ''},[(item && typeof item == 'object')?[_c('p',{staticClass:"client_name"},[_c('strong',[_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.first_name)+" "),(item.company && item.company.tva)?[_vm._v(" - "+_vm._s(item.company.tva)+" ")]:_vm._e()],2)]),_c('p',{staticClass:"client_detail"},[[(item.representative_company_id)?_c('span',[_vm._v(_vm._s(_vm.$t('entity_type.company')))]):_c('span',[_vm._v(_vm._s(_vm.$t('entity_type.private')))])],(item.email)?[_vm._v(" - "+_vm._s(item.email)+" ")]:_vm._e(),(item.company_address && item.company_address.length > 0)?[_c('br'),_c('span',{staticClass:"address_text"},[_vm._v(_vm._s(_vm.computeAddressLabel(_vm.getMainAddress(item.company_address))))])]:_vm._e()],2)]:_vm._e(),(item && typeof item == 'string')?[_vm._v(" "+_vm._s(item.toUpperCase())+" ")]:_vm._e()],2)]}}]),model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}},[_c('template',{slot:"append"},[(_vm.showPencilButton)?_c('v-icon',{on:{"click":function($event){return _vm.editClient()}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.client || _vm.value)?_c('v-icon',{on:{"click":function($event){return _vm.removeClientCompany()}}},[_vm._v("mdi-close")]):_vm._e()],1),_c('template',{slot:"prepend"},[(_vm.client && typeof _vm.client == 'object' && _vm.client.status_id == 10041003)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-account-alert")])]}}],null,false,2282766939)},[_c('span',[_vm._v(_vm._s(_vm.$t("clients.client_blocked_full")))])]):_vm._e()],1)],2),(_vm.showDenomination)?_c('v-select',{staticClass:"to-upper mt-1",staticStyle:{"width":"20%","max-width":"20%"},attrs:{"items":_vm.denominations,"item-value":"id","item-text":"text","outlined":_vm.is_boxes,"hide-details":"","return-object":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();}},model:{value:(_vm.denomination),callback:function ($$v) {_vm.denomination=$$v},expression:"denomination"}}):_vm._e(),(_vm.openEditClientModel)?_c('b7-edit-client-model',{attrs:{"defaultClient":_vm.value,"showDialog":_vm.openEditClientModel,"addMember":false,"expeditor_tva":_vm.tva,"email_required":_vm.email_required,"tva_required":_vm.tva_required,"address_required":_vm.address_required},on:{"addressUpdatedFromPopUp":_vm.emitAddresssChanged,"buttonClicked":_vm.updatedClient,"update:showDialog":function($event){_vm.openEditClientModel=$event},"update:show-dialog":function($event){_vm.openEditClientModel=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }