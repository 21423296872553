<template>
    <div>
        <v-text-field
            :autocomplete="autocompleteValueLocal"
            v-model="tva"
            @input="resetTVAVariables(); "
            class="to-upper"
            :label="$t(label)"
            v-on:keydown.enter.prevent=""
            v-on:keydown="
                handleInput($event, 'alphanumeric', 80)
            "
            :outlined="is_boxes"
            :error-messages="errorMessages ? errorMessages : [] "
            :dense="dense"
            hide-details
            :class="highlight ? 'highlighted_color' : ''"
        >
            <template v-slot:prepend-inner>
                <template v-if="isTVAVerifiedChecking">
                    <v-progress-circular
                        indeterminate
                        size="20"
                        width="2"
                        color="primary"
                    ></v-progress-circular>
                </template>
                <template v-else>
                    <v-icon :class="tva ? '' :'d-none'" :color="isTVAVerified == false ? 'red' : 'green'">
                        {{ isTVAVerified ? 'mdi-checkbox-marked-circle' : (isTVAVerified == false ? 'mdi-close-circle' : '') }}
                    </v-icon>
                </template>
            </template>
            <template  #append>
                <v-icon @click="checkAndEmitData(true)">mdi-refresh</v-icon>
            </template>
        </v-text-field>
    </div>
</template>
<script>
    import _ from 'lodash';
    import { API_BASE_URL } from "@/config";
    import axios from "@/plugins/axios";
    export default {
        model: { prop: "value", event: "input" },
        props:{
            value: {
                type: [String, Number, Object],
                default: null,
            },
            errorMessages: {
                type: [Array],
                default: Array,
            },
            label: {
                type: String,
                default: 'vehicles.clients_model.tva',
            },
            dense:{
                type: Boolean,
                default: false
            },
            highlight: {
                type: Boolean,
                default: false
            }
        },
        data(){
            return {
                header: { Authorization: "Bearer " + this.$store.state.AccessToken },
                autocompleteValueLocal: "off",
                is_boxes: this.getCompanyPropertyFromLocalStorage(
                    "boxes_on_fields",
                    1
                ),
                isTVAVerifiedChecking: null,
                isTVAVerified: null,
                tva: null,
                lastCheckedTva: null,
                lastCheckedisTVAVerifiedChecking: null,
                lastCheckedisTVAVerified: null,
                lastCheckedTvaData: null,
                lastCheckedTvaDateTime: null
            }
        },
        mounted(){
            
        },
        methods:{
          validateTvaWithCompany(tva) {
            if(!tva){
                return;
            }
            axios.get(API_BASE_URL + "/companies/checkvat/" + tva, { headers: this.header })
            .then(({ data }) => {
                if(data && data?.data && typeof data?.data == 'object'){
                    this.isTVAVerified = data?.data?.isValid;
                    if(data?.data?.isValid == false){
                        this.$toast.error(tva + " " + this.$t('invalid_tva'));
                    }
                }
                else{
                    this.isTVAVerified = data?.data ?? null;
                }
            })
          },
            resetTVAVariables(){
                this.isTVAVerified = null;
                this.isTVAVerifiedChecking = false;
            },
            checkIfLastWasSame(tva){
                if(this.lastCheckedTva == tva){
                    return true;
                }
                return false;
            },
            verifyTVACall(tva, force = false){
                console.log("checking_tva_verified_calling", tva, this.lastCheckedTva);
                return new Promise((resolve, reject) => {
                    if(tva){
                        if(!force && this.checkIfLastWasSame(tva)){
                            console.log("checking_tva_verified_inside", tva, this.lastCheckedTva);
                            this.isTVAVerified = this.lastCheckedisTVAVerified;
                            return resolve(this.lastCheckedTvaData);
                        }
                        else{
                            console.log("checking_tva_verified", tva, this.lastCheckedTva);
                            this.lastCheckedTva = tva;
                            this.isTVAVerified = null;
                            this.isTVAVerifiedChecking = true;
                            axios
                            .get(API_BASE_URL + "/clients/checkvat/" + tva + '?force=1', { headers: this.header })
                            .then(({ data }) => {
                                this.isTVAVerified = data.data.isValid;
                                console.log("is valid", data.data.isValid);
                                if(data.data.isValid == false){
                                    this.$toast.error(tva + " " + this.$t('invalid_tva'));
                                }
                                this.lastCheckedisTVAVerified = this.isTVAVerified;
                                this.lastCheckedTvaData = data.data;
                                resolve(data.data);
                            })
                            .catch((error) => {
                                console.log("an error occured " + error);
                                this.isTVAVerified = false;
                                this.lastCheckedisTVAVerified = this.isTVAVerified;
                                this.lastCheckedTvaData = null;
                                this.$toast.error(this.$t('invalid_tva'));
                                reject();
                            })
                            .finally(() => {
                                this.isTVAVerifiedChecking = false;
                                reject();
                            });
                            console.log("checking_tva_verified_inside_after_call", this.isTVAVerified);
                        }
                    }
                    else{
                        this.resetVariables()
                        resolve();
                    }
                })
            },
            checkAndEmitData(force = false){
                if(this.tva){
                    if(force || ! this.checkIfLastWasSame(this.tva)){
                        console.log("checking_and_emit", this.tva);
                        this.verifyTVACall(this.tva, force)
                        .then((data) => {
                            this.$emit("checked", data);
                        })
                        .catch(() => {
                            this.$emit("checked", null);
                        })
                    }
                    else{
                        console.log("checking_tva_before api call", this.lastCheckedTva);
                        this.isTVAVerified = this.lastCheckedisTVAVerified;
                        this.$emit("checked", this.lastCheckedTvaData);
                        return this.lastCheckedTvaData;
                    }
                }
            },
        },
        watch:{

            tva: _.debounce(function (e) {
                this.$emit('input', this.tva);

            }, 500),
            value:{
                handler: function(val) {
                    this.tva = val;
                    this.validateTvaWithCompany(val);
                },
                immediate: true
            }
        }
    }
</script>
<style>
.member_name{
    font-size: 14px;
}
.member_detail{
    font-size: 10px;
    margin: 0px !important;
    white-space: nowrap;
    overflow: hidden;
}
.member_more_detail{
    font-size: 10px;
}
.v-list-item__content{
    padding: 0px;
    margin: 0px;
}
.v-list-item__content ~ input{
    width: 10px !important;
    min-width: 10px;
    max-width: 10px !important;
}
.highlighted_color{
    background-color: #f5fef8c9;
}
</style>