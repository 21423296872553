import HistoryTable from '@/components/UserActionHistory/HistoryTable.vue';
export default {
    component : {
        HistoryTable
    },
    data(){
        return {
            historyTableDialog: false,

        }
    },
    computed: {
        historyTableModelId(){
            return this.$store.state.historyTableModelId;
        },
        historyTableModel(){
            return this.$store.state.historyTableModel;
        },
    },
    methods: {
        openHistoryTableDialog(){
            this.historyTableDialog = false;
            setTimeout(() => this.historyTableDialog = true, 20)
        },
        setHistoryModalData(model, modelId){
            this.$store.state.historyTableModelId = modelId;
            this.$store.state.historyTableModel = model;
        }
    }
}