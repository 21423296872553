<template>
  <div v-if="vehicle">
    <VehicleReadOnlyInfo :vehicle="vehicle" />
    <RentalsPageTab :vehicle="vehicle" />
  </div>
</template>
<script>
import VehicleReadOnlyInfo from "../../components/Vehicles/VehicleReadOnlyInfo.vue";
import { mapState } from "vuex";
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import RentalsPageTab from "../../components/Rentals/RentalsPageTab.vue";
export default {
  components: {
    VehicleReadOnlyInfo,
    RentalsPageTab,
  },
  data() {
    return {
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      vehicle: null,
    };
  },
  computed: {
    ...mapState({
      vehicleId: (state) => state.vehicleId,
    }),
  },
  mounted() {
    setTimeout(() => {}, 6000);
    this.vehicle = this.setVehicle();
  },
  watch: {
    vehicleId() {
      this.setVehicle();
    },
  },
  methods: {
    setVehicle() {
      if (!this.vehicleId) {
        this.$router.push("/rentals");
      }

      this.$store.commit("setLoading", true);
      axios
        .get(API_BASE_URL + "/client_vehicles/" + this.vehicleId, {
          headers: this.header,
        })
        .finally(() => this.$store.commit("setLoading", false))
        .then((response) => {
          this.vehicle = response.data || null;
        })
        .catch((error) => {});
    },
  },
};
</script>
