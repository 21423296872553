<template>
  <div>
    <v-data-table
        :headers="tableHeaders"
        :items="computedSchema"
        :items-per-page="10"
        class="elevation-1"
        @click:row="(item) =>{
        selectedSchema = item;
        launchForm();
        }"
    >
    </v-data-table>
    <div class="mt-2 text-muted">
        <v-icon>mdi-information</v-icon>
        {{ $t('counter_reminder_help') }}
    </div>
    <span
        id="new-schema"
        @click="() => {
      selectedSchema = null;
      launchForm();
    }">
      <order-reminder-schema-form
          v-model="formModal"
          :order-reminder-schema="selectedSchema"
          @created="getOrderReminderSchemas"
          @updated="getOrderReminderSchemas"
      /></span>
  </div>
</template>
<script>
import _axios from "@/plugins/axios";
import {API_BASE_URL} from "@/config";
import OrderReminderSchemaForm from "@/views/OrderReminderSchema/Form";

export default {
  name: 'OrderReminderSchemaListing',
  components: {
    OrderReminderSchemaForm
  },
  data() {
    return {
      orderReminderSchemas: [],
      selectedSchema: null,
      formModal: false,
      tableHeaders: [
        {
          text: this.$t('srno'),
          value: 'serial'
        },
        {
          text: this.$t('at_counter'),
          value: 'at_counter'
        },
        {
          text: this.$t('next_reminder_in_days'),
          value: 'next_reminder_in_days_label'
        }
      ],
    };
  },
  computed: {
    computedSchema() {
      return this.orderReminderSchemas.map((i, index) => ({
        ...i,
        serial: index + 1,
        next_reminder_in_days_label: i.next_reminder_in_days + ' ' + this.$t('clients.days')
      }))
    }
  },
  mounted() {
    this.getOrderReminderSchemas();
  },
  methods: {
    launchForm() {
      this.formModal = false;
      setTimeout(() => {
        this.formModal = true;
      }, 10);
    },
    async getOrderReminderSchemas() {
      try {
        const response = await _axios.get(API_BASE_URL + '/order_reminder_schemas');
        this.orderReminderSchemas = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
}
</script>