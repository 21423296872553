var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4"},[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"width":"100%"},attrs:{"fixed-header":"","footer-props":{
                          'items-per-page-options': _vm.dataTableOptions,
                      },"hide-default-footer":true,"headers":_vm.headers,"items":_vm.attachments,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.isLoading,"loading-text":this.$t('loading'),"items-per-page":_vm.itemsPerPage,"item-key":"identifier","height":"400px","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"mustSort":""},on:{"update:page":_vm.updatePagination,"update:items-per-page":_vm.updateItemsPerPage,"click:row":_vm.editItem,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":[function($event){_vm.sortBy=$event},_vm.updateSort],"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":[function($event){_vm.sortDesc=$event},_vm.updateSortOrder]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                      var item = ref.item;
return [_c('tr',[_c('td',[(item)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":_vm.getItemIcon(item)},on:{"click":function($event){return _vm.openUrl(item)}}},[_vm._v(" "+_vm._s(_vm.getItemIconColor(item))+" ")]):_vm._e()],1),_c('td',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(item.created_at)+" ")]),_c('td',{on:{"click":function($event){return _vm.editItem(item)}}},[(
                                          _vm.editModeItem &&
                                              _vm.editModeItem.id == item.id
                                      )?[_c('v-text-field',{staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('attachment.title_optional'),"outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}})]:[_vm._v(" "+_vm._s((item.title ? item.title : "" ).toUpperCase())+" ")]],2),_c('td',{on:{"click":function($event){return _vm.editItem(item)}}},[(
                                          _vm.editModeItem &&
                                              _vm.editModeItem.id == item.id
                                      )?[_c('v-text-field',{staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('attachment.description'),"outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})]:[_vm._v(" "+_vm._s(item.description ? item.description .substr(0, 20) .toUpperCase() : "")+" ")]],2),_c('td',[(
                                          _vm.editModeItem &&
                                              _vm.editModeItem.id == item.id
                                      )?[(item)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"blue"},on:{"click":function($event){return _vm.cancelEdit()}}},[_vm._v(" mdi-close ")]):_vm._e(),(item)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"green"},on:{"click":function($event){return _vm.saveItem(item)}}},[_vm._v(" mdi-content-save ")]):_vm._e()]:_vm._e(),(item)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"warning"},on:{"click":function($event){return _vm.deleteAttachmentAfterConfirmation(item.id)}}},[_vm._v(" mdi-delete ")]):_vm._e()],2)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }