<template>
  <div class="mt-4">
    <v-data-table
        fixed-header
        :footer-props="{
                            'items-per-page-options': dataTableOptions,
                        }"
        :hide-default-footer="true"
        :headers="headers"
        :items="attachments"
        :options="options"
        :server-items-length="totalItems"
        :loading="isLoading"
        :loading-text="this.$t('loading')"
        class="elevation-0"
        :items-per-page="itemsPerPage"
        item-key="identifier"
        @update:page="updatePagination"
        @update:items-per-page="updateItemsPerPage"
        @click:row="editItem"
        height="400px"
        style="width: 100%"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @update:sort-by="updateSort"
        @update:sort-desc="updateSortOrder"
        mustSort
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>
            <v-icon
                @click="openUrl(item)"
                v-if="item"
                :color="getItemIcon(item)"
                class="mr-1"
            >
              {{ getItemIconColor(item) }}
            </v-icon>
          </td>
          <td @click="editItem(item)">
            {{ item.created_at }}
          </td>
          <!-- <td @click="editItem(item)">
              <template
                  v-if="
                      item.attachment_group_id &&
                          item.group
                  "
              >
                  {{ item.group.group }}
              </template>
          </td> -->
          <td @click="editItem(item)">
            <template
                v-if="
                                            editModeItem &&
                                                editModeItem.id == item.id
                                        "
            >
              <v-text-field
                  :autocomplete="
                                                autocompleteValueLocal
                                            "
                  v-model="item.title"
                  class="to-upper"
                  :label="
                                                $t('attachment.title_optional')
                                            "
                  :outlined="is_boxes"
                  :dense="is_boxes"
              ></v-text-field>
            </template>
            <template v-else>
              {{
                (item.title
                        ? item.title
                        : ""
                ).toUpperCase()
              }}
            </template>
          </td>
          <td @click="editItem(item)">
            <template
                v-if="
                                            editModeItem &&
                                                editModeItem.id == item.id
                                        "
            >
              <v-text-field
                  :autocomplete="
                                                autocompleteValueLocal
                                            "
                  v-model="item.description"
                  class="to-upper"
                  :label="
                                                $t('attachment.description')
                                            "
                  :outlined="is_boxes"
                  :dense="is_boxes"
              ></v-text-field>
            </template>
            <template v-else>
              {{
                item.description
                    ? item.description
                        .substr(0, 20)
                        .toUpperCase()
                    : ""
              }}
            </template>
          </td>
          <td>
            <template
                v-if="
                                            editModeItem &&
                                                editModeItem.id == item.id
                                        "
            >
              <v-icon
                  v-if="item"
                  color="blue"
                  class="mr-1"
                  @click="cancelEdit()"
              >
                mdi-close
              </v-icon>
              <v-icon
                  v-if="item"
                  color="green"
                  class="mr-1"
                  @click="saveItem(item)"
              >
                mdi-content-save
              </v-icon>
            </template>
            <v-icon
                v-if="item"
                color="warning"
                class="mr-1"
                @click="deleteAttachmentAfterConfirmation(item.id)"
            >
              mdi-delete
            </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import { API_BASE_URL } from "@/config";
import axios from "@/plugins/axios";

export default {
  name: "RentalAttachmentsList",
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage(
          "boxes_on_fields",
          1
      ),
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      attachments: [],
      options: {},
      totalItems: 0,
      isLoading: false,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      sortBy: "description",
      sortDesc: false,
      editModeItem: null,
      autocompleteValueLocal: "off",


    }
  },
  computed: {
    headers() {
      return [
        {
          text: "",
          align: "start",
          sortable: true,
          value: "date",
          width: "10px",
        },
        {
          text: this.$t("attachment.date"),
          align: "start",
          sortable: true,
          value: "date",
        },
        // {
        //     text: this.$t("attachment.group"),
        //     align: "start",
        //     sortable: false,
        //     value: "group",
        // },
        {
          text: this.$t("attachment.title"),
          align: "start",
          sortable: false,
          value: "title",
        },
        {
          text: this.$t("attachment.description"),
          align: "start",
          sortable: false,
          value: "description",
        },
        {
          text: this.$t("attachment.actions"),
          align: "start",
          sortable: false,
          value: "actions",
        },
      ];
    },
  },
  methods : {
    deleteAttachmentAfterConfirmation(data) {
      this.confirmationDialogData = data;
      this.confirmationDialogConfirmationText = this.$t(
          "attachment.delete_attachment_confirmation"
      );
      this.confirmationDialogFalseText = this.$t("attachment.cancel");
      this.confirmationDialogTrueText = this.$t(
          "attachment.delete"
      );
      this.confirmationDialogOperation = "delete_attachment";
      this.confirmationDialog = true;
    },
    updatePagination(page) {
      this.page = page;
      this.fetchData();
    },
    updateItemsPerPage(itemsPerPage) {
      this.itemsPerPage =
          itemsPerPage == -1 ? this.totalItems : itemsPerPage;
      this.fetchData();
      this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
    },
    editItem(item) {
      this.editModeItem = item;
    },
    updateSort(sortBy) {
      if (typeof sortBy == "object") {
        this.sortBy = sortBy[0];
      } else {
        this.sortBy = sortBy == undefined ? "id" : sortBy;
      }
    },
    updateSortOrder(sortDesc) {
      if (typeof sortDesc == "object") {
        !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      } else {
        !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      }
      console.log("console order => ", this.sortOrd);
      this.fetchData();
    },
    openUrl(item) {
      // window.open(
      //     API_BASE_URL +
      //         "/attachments/file/" +
      //         `${item.resource_key_id}/${item.file_name}`,
      //     "_blank"
      // );
      window.open(item.signed_url);
    },
    getItemIcon(item) {
      if (item.mime_type.includes("video")) {
        return "orange";
      } else if (item.mime_type.includes("image")) {
        return "blue";
      } else if (item.mime_type.includes("audio")) {
        return "blue";
      } else {
        return "black";
      }
    },
    getItemIconColor(item) {
      if (item.mime_type.includes("video")) {
        return "mdi mdi-multimedia";
      } else if (item.mime_type.includes("image")) {
        return "mdi mdi-image";
      } else if (item.mime_type.includes("audio")) {
        return "mdi mdi-volume-high";
      } else {
        return "mdi mdi-file";
      }
    },
    cancelEdit() {
      console.log("cancel edit");
      this.editModeItem = null;
      console.log("cancel edit", this.editModeItem);
    },
    saveItem(item) {
      let data = {
        title: item.title ?? "",
        description: item.description ?? "",
      };
      this.isLoading = true;
      axios
          .put(API_BASE_URL + "/attachments/" + item.id, data, {
            headers: {
              Authorization:
                  "Bearer " + this.$store.state.AccessToken,
            },
          })
          .then(({ data }) => {})
          .catch(function(error) {
            console.log("an error occured " + error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      this.cancelEdit();
    },

  }
}
</script>

<style scoped>

</style>