var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-8"},[_c('v-row',_vm._l((_vm.getPropertiesForSection(
                _vm.prop_company_properties,
                'email'
            ).slice(0, 5)),function(property,index){return _c('v-col',{key:index,staticClass:"ma-0 pa-0 pl-4"},[(!_vm.checkIfPropertyIsEmailSignature(property))?_c('v-text-field',{attrs:{"type":_vm.isPasswordField(property) ? 'password' : 'text',"label":_vm.getTranslation(property.company_setting_key)},model:{value:(property.value),callback:function ($$v) {_vm.$set(property, "value", $$v)},expression:"property.value"}}):_vm._e()],1)}),1),_c('v-row',_vm._l((_vm.getPropertiesForSection(
                _vm.prop_company_properties,
                'email'
            ).slice(5, 7)),function(property,index){return _c('v-col',{key:index,staticClass:"ma-0 pa-0 pl-4",attrs:{"cols":"6"}},[(!_vm.checkIfPropertyIsEmailSignature(property))?_c('v-text-field',{attrs:{"type":_vm.isPasswordField(property) ? 'password' : 'text',"label":_vm.getTranslation(property.company_setting_key)},model:{value:(property.value),callback:function ($$v) {_vm.$set(property, "value", $$v)},expression:"property.value"}}):_vm._e()],1)}),1),_c('v-row',_vm._l((_vm.getPropertiesForSection(
                _vm.prop_company_properties,
                'email'
            ).slice(7, 9)),function(property,index){return _c('v-col',{key:index,staticClass:"ma-0 pa-0 pl-4",attrs:{"cols":"6"}},[(!_vm.checkIfPropertyIsEmailSignature(property))?_c('v-text-field',{attrs:{"type":_vm.isPasswordField(property) ? 'password' : 'text',"label":_vm.getTranslation(property.company_setting_key)},model:{value:(property.value),callback:function ($$v) {_vm.$set(property, "value", $$v)},expression:"property.value"}}):_vm._e()],1)}),1),_vm._l((_vm.getPropertiesForSection(
            _vm.prop_company_properties,
            'email'
        )),function(property,index){return _c('v-row',{key:'note-' + index},[(_vm.checkIfPropertyIsEmailSignature(property))?_c('v-col',{staticClass:"ma-0 pa-0 pl-4",attrs:{"id":"email_pdf","cols":"12","sm":"12"}},[_c('h4',{staticClass:"ma-4 ml-0"},[_vm._v(_vm._s(_vm.getTranslation(property.company_setting_key)))]),_c('NoteEditor',{attrs:{"label":_vm.getTranslation(property.company_setting_key),"showLabel":false},model:{value:(property.value),callback:function ($$v) {_vm.$set(property, "value", $$v)},expression:"property.value"}})],1):_vm._e()],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }