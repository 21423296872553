<template>
  <div>
    <v-data-table
        :items="formattedWorkingShifts"
        :headers="headers"
        :height="'calc(-230px + 100vh)'"
        @click:row="rowClickHandler"
      >

    </v-data-table>
    <span id="working-shift-form" @click="launchWorkingShiftModal(true)">
      <working-shift-form
          v-model="workingShiftDialog"
          :workingShift="selectedWorkingShift"
          @created="createdHandler"
          @updated="updatedHandler"
      />
    </span>
  </div>
</template>
<script>
import {API_BASE_URL} from "@/config";
import axios from "axios";
import clockingMixin from "@/mixins/clockingMixin";
import WorkingShiftForm from "@/components/Clocking/WorkingShiftForm.vue";

export default {
  name: 'WorkingShiftListing',
  mixins: [clockingMixin],
  components: {
    WorkingShiftForm,
  },
  data() {
    return {
      workingShifts: [],
      workingShiftDialog: false,
      selectedWorkingShift: null
    }
  },
  mounted() {
    this.getWorkingShifts()
  },
  computed: {
    headers() {
      let headers = [
        {text: this.$t('srno'), value: 'serial'},
        {text: this.translate('name'), value: 'name'},
        {text: this.translate('start_time'), value: 'start_time_label'},
        {text: this.translate('end_time'), value: 'end_time_label'},
        {text: this.translate('start_break_time'), value: 'start_break_time_label'},
        {text: this.translate('employees'), value: 'employees_count'},
        {text: this.translate('created_at'), value: 'created_at'},
      ]

      return headers.map(header => {
        return {
          ...header,
          align: 'start',
          sortable: false,
        }

      })
    },
    formattedWorkingShifts(){
return this.workingShifts.map((workingShift, index) => {
        return {
          ...workingShift,
          serial: index + 1,
          start_time_label: this.formatTimeWithLocale(workingShift.start_time),
          end_time_label: this.formatTimeWithLocale(workingShift.end_time),
          start_break_time_label: this.formatTimeWithLocale(workingShift.start_break),
          created_at: this.formatDateWithLocale(workingShift.created_at, true),
        }
      })
    }
  },
  methods: {
    launchWorkingShiftModal(isNew = false) {
      this.workingShiftDialog = false;
      if(isNew){
        this.selectedWorkingShift = null
      }
      setTimeout(() => {
        this.workingShiftDialog = true;
      }, 10)
    },
    getWorkingShifts() {
      return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + '/employees/working_shifts', {headers: this.axiosOptions})
            .then(response => {
              this.workingShifts = response.data.data
              resolve(this.workingShifts)
            }).catch(error => {
          reject(error)
        })
      })
    },
    createdHandler(newWorkingShift){
      this.workingShifts.unshift(newWorkingShift)
      this.workingShiftDialog = false
    },
    updatedHandler(newWorkingShift){
      this.workingShiftDialog = false;
      this.workingShifts = this.workingShifts.map(workingShift => {
        if (workingShift.id === newWorkingShift.id) {
          return newWorkingShift
        }
        return workingShift
      })
    },
    rowClickHandler(workingShift){
      this.selectedWorkingShift = workingShift
      this.launchWorkingShiftModal()
      setTimeout(() => this.selectedWorkingShift = null, 1000)
    }
  }
}
</script>