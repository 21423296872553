<template>
  <v-card class="elevation-0">
    <v-tabs
        v-model="tab"
        height="50px"
        background-color="primary"
        dark
        v-if="checkTag('client_address') || checkTag('billing') || checkTag('vehicles') || checkTag('scheduler')"
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#client-addresses" v-if="checkTag('client_address')">
        <v-icon>mdi-map-search-outline</v-icon>
        {{$t('clients.tabs.addresses')}}
      </v-tab>

      <v-tab href="#client-bills" v-if="checkTag('billing')">
        <v-icon>mdi-currency-eur</v-icon>
        {{$t('clients.tabs.bills')}}
      </v-tab>

      <v-tab href="#client-cars" v-if="checkTag('vehicles')">
        <v-icon>mdi-car-back</v-icon>
        {{$t('clients.tabs.vehicles')}}
      </v-tab>

      <v-tab href="#client-schedules" v-if="checkTag('scheduler')">
        <v-icon>mdi-calendar</v-icon>
        {{$t('clients.tabs.schedules')}}
      </v-tab>

      <v-tab href="#client-members" v-if="checkTag('clients') && client.representative_company_id">
        <v-icon>mdi-account-tie</v-icon>
        {{$t('clients.tabs.members')}}
      </v-tab>

      <v-tab href="#client-working-points" v-if="checkTag('clients') && client.representative_company_id">
        <v-icon>mdi-office-building-marker-outline</v-icon>
        {{$t('clients.tabs.working_points')}}
      </v-tab>

      <!-- <v-tab href="#client-banks" v-if="checkTag('clients') && client.representative_company_id">
        <v-icon>mdi-bank</v-icon>
        {{$t('company_settings.tabs.bank_accounts')}}
      </v-tab> -->
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item  :key="'client-addresses'" :value="'client-addresses'">
          <addresses-tab 
            v-if="tab == 'client-addresses'"
            :client="client"
          ></addresses-tab>
      </v-tab-item>

      <v-tab-item :key="'client-bills'" :value="'client-bills'">
          <bills-tab 
            v-if="tab == 'client-bills'"
            class="pa-4"
            :client="client"
          ></bills-tab>
      </v-tab-item>

      <v-tab-item :key="'client-cars'" :value="'client-cars'" >
          <vehicle-tab
            v-if="tab == 'client-cars'"
            :client="client"
          ></vehicle-tab>
      </v-tab-item>

      <v-tab-item :key="'client-schedules'" :value="'client-schedules'">
          <schedule-tab
            v-if="tab == 'client-schedules'"
            :client="client"
          ></schedule-tab>
      </v-tab-item>

      <v-tab-item v-if="client.representative_company_id" :key="'client-members'" :value="'client-members'">
          <client-members
            v-if="tab == 'client-members'"
            :client="client"
          ></client-members>
      </v-tab-item>

      <v-tab-item v-if="client.representative_company_id" :key="'client-working-points'" :value="'client-working-points'">
          <client-working-point
            v-if="tab == 'client-working-points'"
            :client="client"
          ></client-working-point>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import AddressesTab from '@/components/Client/AddressesTab';
import BillsTab from '@/components/Client/BillsTab';
import VehicleTab from '@/components/Client/VehicleTab';
import ScheduleTab from '@/components/Client/ScheduleTab';
import ClientMembers from '@/components/Client/ClientMembers';
import ClientWorkingPoint from '@/components/Client/ClientWorkingPoint';

export default {
  name:       "ClientTabs",
  components: {
    'addresses-tab': AddressesTab,
    'bills-tab':     BillsTab,
    'vehicle-tab':     VehicleTab,
    'schedule-tab':     ScheduleTab,
    'client-members': ClientMembers,
    'client-working-point': ClientWorkingPoint
  },
  props:{
    client: [Object]
  },
  data() {
    return {
      tab:  null,
      text: "",
    }
  },
  computed: {}
}
</script>

<style scoped>

</style>
