<template>
  <div v-if="employeeContract">
    <v-dialog :value="value" max-width="1000" scrollable>
      <v-card>
        <v-card-title>
          {{ translate('employee_contract') }}
          <v-spacer></v-spacer>
          <v-btn icon @click="toggleDeleteEmployeeContract">
            <v-icon color="red">mdi-trash-can</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col :sm="7">
              <v-card-title>{{ translate('contract_information') }}</v-card-title>
              <v-simple-table>
                <tbody>
                <tr>
                  <td>{{ translate('employee') }}</td>
                  <td>{{ employeeContract.employee.name }}</td>
                </tr>
                <tr>
                  <td>{{ translate('contract_type') }}</td>
                  <td>{{ translate('types.' + employmentContract.type.key) }}</td>
                </tr>
                <tr v-if="isset(employeeContract.start_date)">
                  <td>{{ translate('contract_start_date') }}</td>
                  <td>{{ formatDateWithLocale(employeeContract.start_date, true) }}</td>
                </tr>
                <tr v-if="isset(employeeContract.end_date)">
                  <td>{{ translate('contract_end_date') }}</td>
                  <td>{{ formatDateWithLocale(employeeContract.end_date, true) }}</td>
                </tr>
                <tr v-if="isset(employeeContract.end_date)">
                  <td>{{ translate('status') }}</td>
                  <td>{{ employeeContract.is_active ? 'Active' : 'Not Active' }}</td>
                </tr>
                <tr>
                  <td>{{ translate('creation_date') }}</td>
                  <td>{{ formatDateWithLocale(employeeContract.created_at, true) }}</td>
                </tr>
                <tr>
                  <td>{{ translate('last_modified') }}</td>
                  <td>{{ formatDateWithLocale(employeeContract.updated_at, true) }}</td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col :sm="5">
              <v-card-title>{{ translate('default_detail') }}</v-card-title>
              <v-simple-table>
                <tbody>
                <tr>
                  <td>{{ translate('working_hours_per_day_full') }}</td>
                  <td>{{ employmentContract.work_hours_per_day }} {{ $t('measurement_units.hour') }}(s)</td>
                </tr>

                <tr>
                  <td>{{ translate('break_time_per_day_full') }}</td>
                  <td>{{ employmentContract.break_time_period_label }}</td>
                </tr>

                <tr>
                  <td>{{ translate('break_time_start_full') }}</td>
                  <td>{{ employmentContract.break_time_start }}</td>
                </tr>


                <tr>
                  <td>{{ translate('work_days_per_month') }}</td>
                  <td>{{ employmentContract.work_days_period_label }}</td>
                </tr>

                <tr>
                  <td>{{ translate('vacation_days') }}</td>
                  <td>{{ employmentContract.vacation_days_period_label }}</td>
                </tr>

                <tr>
                  <td>{{ translate('resumption_time') }}</td>
                  <td>{{ employmentContract.work_time_start }}</td>
                </tr>

                <tr>
                  <td>{{ translate('closing_time') }}</td>
                  <td>{{ employmentContract.work_time_end }}</td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row v-if="showCustomDetail">

            <v-col :sm="12">
              <v-card-subtitle>{{ translate('custom_contract_detail') }}</v-card-subtitle>
              <v-simple-table>
                <thead>
                <tr>
                  <th>{{ $t('srno') }}</th>
                  <th>{{ translate('filter_options.day') }}</th>
                  <th>{{ translate('working_hours_per_day') }}</th>
                  <th>{{ translate('break_time_start') }}</th>
                  <th>{{ translate('resumption_time') }}</th>
                  <th>{{ translate('closing_time') }}</th>
                  <th style="width: 100px"></th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(detail, index) in employeeContract.details"
                    :key="detail.id"
                    @mouseover="getElementById('action_' + detail.id).style.display='block'"
                    @mouseleave="getElementById('action_' + detail.id).style.display='none'"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ $t('days_long.' + daysOfTheWeek[detail.dow]) }}</td>
                  <td style="text-align: center">{{ detail.working_hours }}</td>
                  <td>{{ formatTimeWithLocale(detail.start_break_time) }}</td>
                  <td>{{ formatTimeWithLocale(detail.start_time + ":00") }}</td>
                  <td>{{ formatTimeWithLocale(detail.end_time + ":00") }}</td>
                  <td>
                    <span :id="'action_' + detail.id" style="display: none">
<!--                    <v-btn icon>-->
                      <!--                      <v-icon color="success">mdi-pencil</v-icon>-->
                      <!--                    </v-btn>-->

                    <v-btn icon @click="toggleDeleteConfirmation(detail.id)">
                      <v-icon color="red">mdi-trash-can</v-icon>
                    </v-btn>
                    </span>
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <span @click="close"><CloseButton/></span>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirmation-model
        :showDialog.sync="confirmationDialog"
        :openedForOperation.sync="confirmationDialogOperation"
        :text.sync="confirmationDialogText"
        :trueText.sync="confirmationDialogTrueText"
        :falseText.sync="confirmationDialogFalseText"
        @buttonClicked="confirmationButtonClicked"
    >
    </confirmation-model>
    <employee-contract-form
        v-model="formDialog"
        :employeeContract="employeeContract"
        @updated="(data) => $emit('updated', data)"
    />
  </div>
</template>
<script>
import clockingMixin from "@/mixins/clockingMixin";
import CloseButton from "@/components/Buttons/CloseButton.vue";
import ConfirmationModel from "@/components/ConfirmationModel.vue";
import  EmployeeContractForm from "@/views/Clocking/EmployeeContracts/Form.vue";
import {API_BASE_URL} from "@/config";
import axios from "@/plugins/axios";

export default {
  name: "EmployeeContractDetails",
  components: {CloseButton, ConfirmationModel, EmployeeContractForm},
  mixins: [clockingMixin],
  emits: ['input', 'updated', 'deleted'],
  props: {
    value: {
      default: false,
    },
    employeeContract: {
      default: null,
    }
  },
  data() {
    return {
      daysOfTheWeek: ['', 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
      confirmationDialog: false,
      confirmationDialogOperation: null,
      confirmationDialogSecondaryOperation: null,
      confirmationDialogText: '',
      confirmationDialogTrueText: '',
      confirmationDialogFalseText: '',
      confirmationDialogTarget: null,
      formDialog: false,
    }
  },
  async mounted() {
    this.clockingTypes = await this.getClockingTypes();
    this.employmentContractTypes = await this.getCodes('employment_contract_types', false)
    this.measurementUnits = await this.getCodes('measurement_units', false)
  },
  computed: {
    employmentContract() {
      if (!this.employeeContract) return null;

      let item = this.employeeContract.contract;


      let type = this.employmentContractTypes.find(type => type.id === item.type_id);
      item.clocking_type = this.translate('types.' + type.key)

      let measurementUnit = this.measurementUnits.find(type => type.id === item.break_time_period)
      item.break_time_period_label = item.break_time_per_day + ' ' + this.$t('measurement_units.' + measurementUnit.key)

      let workDaysPeriod = this.measurementUnits.find(type => type.id === item.work_days_period)
      item.work_days_period_label = item.work_days + ' ' + this.$t('measurement_units.' + workDaysPeriod.key)

      let vacationDaysPeriod = this.measurementUnits.find(type => type.id === item.vacation_days_period)
      item.vacation_days_period_label = item.vacation_days + ' ' + this.$t('measurement_units.' + vacationDaysPeriod.key) + '(s)'

      item.break_time_start = this.formatTimeWithLocale(item.default_start_break_time)

      item.work_time_start = this.formatTimeWithLocale(item.default_start_time)
      item.work_time_end = this.formatTimeWithLocale(item.default_end_time)

      return item

    },
    showCustomDetail() {
      return this.employeeContract?.details?.length > 0
    }
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
    toggleDeleteConfirmation(targetId) {

      this.confirmationDialog = false;
      setTimeout(() => {
        let detail = this.employeeContract.details.find(detail => detail.id === targetId);
        this.confirmationDialog = true
        this.confirmationDialogOperation = "delete";
        this.confirmationDialogSecondaryOperation = 'delete_detail';
        this.confirmationDialogText = this.translate('delete_confirmation_employee_detail', {day: this.$t('days_long.' + this.daysOfTheWeek[detail.dow])})
        this.confirmationDialogTrueText = this.$t('yes')
        this.confirmationDialogFalseText = this.$t('no')
        this.confirmationDialogTarget = targetId;
      }, 10)
    },
    toggleDeleteEmployeeContract() {
      this.confirmationDialog = false;
      setTimeout(() => {
        this.confirmationDialog = true
        this.confirmationDialogOperation = "delete";
        this.confirmationDialogSecondaryOperation = 'delete_employee_contract';
        this.confirmationDialogText = this.translate('delete_confirmation_employee_contract')
        this.confirmationDialogTrueText = this.$t('yes')
        this.confirmationDialogFalseText = this.$t('no')
        this.confirmationDialogTarget = this.employeeContract.id;
      }, 10)
    },
    confirmationButtonClicked(response) {
      if (!response) {
        this.confirmationDialog = false;
        return false;
      }

      if (this.confirmationDialogSecondaryOperation === "delete_detail") {
        this.deleteDetail(this.confirmationDialogTarget);
      }

      if (this.confirmationDialogSecondaryOperation === "delete_employee_contract") {
        this.deleteEmployeeContract();
      }
    },
    deleteDetail(id) {
      axios.delete(API_BASE_URL + `/employees/contracts/${this.employeeContract.id}/details/${id}`, {headers: this.axiosOptions})
          .then(response => {
            this.$emit('updated', response.data.data);
          })
          .catch(error => {
            console.log(error)
          })
    },
    deleteEmployeeContract() {
      axios.delete(API_BASE_URL + `/employees/contracts/${this.employeeContract.id}`, {headers: this.axiosOptions})
          .then(response => {
            this.$emit('deleted', this.employeeContract);
          })
          .catch(error => {
            this.$toast.error(this.translate('contract_has_clocking_error'))
          })
    },
    showFormDialog(){
      this.formDialog = false;
      setTimeout(() => this.formDialog = true, 10);
    },

  }
}
</script>