var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800"},on:{"click:outside":function($event){return _vm.closeArticleDotModel()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeArticleDotModel()}},model:{value:(_vm.modifyDots),callback:function ($$v) {_vm.modifyDots=$$v},expression:"modifyDots"}},[_c('v-card',{attrs:{"loading":_vm.cardLoading}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.article.description))])]),_c('v-card-text',[(_vm.iserror)?_c('span',{staticClass:"text-error"},[_vm._v(_vm._s(_vm.$t("stocks.dot_greater_then_stock_error")))]):_vm._e(),_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"width":"100%"},attrs:{"fixed-header":"","footer-props":{
                    'items-per-page-options': _vm.dataTableOptions,
                },"headers":_vm.headers,"items":_vm.dot_items,"items-per-page":_vm.itemsPerPage,"options":_vm.options,"sort-by":_vm.sortBy,"dense":"","loading":_vm.isLoading,"loading-text":this.$t('loading'),"item-key":"property","height":"30vh"},on:{"update:options":function($event){_vm.options=$event},"update:page":_vm.updatePagination,"update:items-per-page":_vm.updateItemsPerPage},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pt-4",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{staticClass:"mr-3 pb-5 to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('receptions.modify_article.dot'),"clearable":true,"clear-icon":"mdi-close","tabindex":"100","outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.addDotToDataTable()},function($event){return _vm.handleInput($event, 'int', 4)}]},model:{value:(_vm.property),callback:function ($$v) {_vm.property=$$v},expression:"property"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{staticClass:"mr-3 to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t(
                                            'receptions.modify_article.quantity'
                                        ),"clearable":true,"tabindex":"101","clear-icon":"mdi-close","outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.addDotToDataTable()},function($event){return _vm.handleInput($event, 'int', 6)}],"input":_vm.checkError},model:{value:(_vm.counter),callback:function ($$v) {_vm.counter=$$v},expression:"counter"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{attrs:{"disabled":!(
                                            !_vm.$v.property.minLength &&
                                            !_vm.$v.counter.minValue
                                        ),"color":"primary"},on:{"click":function($event){return _vm.addDotToDataTable()}}},[_vm._v(" "+_vm._s(_vm.$t("receptions.modify_article.add"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-plus-thick ")])],1)],1)],1)],1)]},proxy:true},{key:"item.counter",fn:function(ref){
                                        var item = ref.item;
return [_c('td',[_c('v-text-field',{staticClass:"mr-3 pb-4 to-upper",staticStyle:{"width":"40px"},attrs:{"autocomplete":_vm.autocompleteValueLocal,"hide-details":"auto","outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){return _vm.handleInput($event, 'int', 6)},"input":_vm.checkError},model:{value:(item.counter),callback:function ($$v) {_vm.$set(item, "counter", $$v)},expression:"item.counter"}})],1)]}},{key:"item.actions",fn:function(ref){
                                        var item = ref.item;
return [_c('td',{attrs:{"align":"right"}},[_c('v-icon',{attrs:{"color":"red","right":""},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" mdi-delete ")])],1)]}}])})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey-lighten-2"},on:{"click":function($event){return _vm.closeArticleDotModel()}}},[_vm._v(" "+_vm._s(_vm.$t("receptions.modify_article.cancel"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-keyboard-return ")])],1),_c('v-btn',{attrs:{"disabled":_vm.iserror,"color":"green","dark":!_vm.iserror},on:{"click":function($event){return _vm.submitArticleDotModel()}}},[_vm._v(" "+_vm._s(_vm.$t("receptions.modify_article.save"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-content-save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }