<template>
  <div>
    <v-text-field
      :class="{ 'mr-5': marginRight }"
      class="ml-5 text-right"
      :label="$t('stocks.client_price') + '.X'"
      v-model="stockUnits"
      @keydown="
        ($event) => {
          handleInput($event, 'float');
          // if keycode  is equivalent to 0, prevent default
          if ($event.keyCode == 48) {
            $event.preventDefault();
          }

          // if its value greater than 4 decimal points and another number is to be added, prevent default
          if (
            $event.target.value.split('.')[1] &&
            $event.target.value.split('.')[1].length >= 4 &&
            $event.keyCode != 8
          ) {
            $event.preventDefault();
          }
        }
      "
      @focusout="
        ($event) =>
          $event.target.value < 1
            ? (stockUnits = 1)
            : (stockUnits = $event.target.value)
      "
      @focus="($event) => $event.target.select()"
    >
      <!-- Prepend x as an icon -->
      <!-- <template v-slot:prepend-inner>
        <v-icon style="font-size:larger">mdi-close</v-icon>
      </template> -->
    </v-text-field>
  </div>
</template>
<script>
export default {
  name: "ClientPriceInput",
  data() {
    return {
      stockUnits: this.value,
    };
  },
  props: {
    value: {
      default: 4,
    },
    marginRight: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    stockUnits(val) {
      this.$emit("input", val);
    },
    value(val){
      this.stockUnits = val;
    }
  },
};
</script>
<style scoped>
.text-right >>> input {
  text-align: right;
  padding-right: 2px;

  width: 100%;
}
</style>
