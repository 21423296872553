var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-tabs',{attrs:{"background-color":"primary","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"label_reserves"},[_vm._v(" "+_vm._s(_vm.$t("stocks.label_reserve"))+" ")]),_c('v-tab',{key:"supplier"},[_vm._v(" "+_vm._s(_vm.$t("scheduler.supplier"))+" "+_vm._s(_vm.$t("vehicles.vehicle_tab.orders"))+" ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:'reserves'},[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.reservedStocksHeaders,"items":_vm.reservedStockTableItems,"hide-default-footer":true,"items-per-page":_vm.reservedStockTableItems.length},scopedSlots:_vm._u([{key:"item.document_number",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({
                color: 'blue',
                textDecoration: 'underline',
                cursor: 'pointer',
              }),on:{"click":function($event){return _vm.navigateToOrdersPage(item.order_id)}}},[_vm._v(" "+_vm._s(item.identifier)+" ")])]}},{key:"item.appointment_date",fn:function(ref){
              var item = ref.item;
return [(item.appointment_date)?_c('span',{style:({
                color: 'blue',
                textDecoration: 'underline',
                cursor: 'pointer',
              }),on:{"click":function($event){return _vm.navigateToAppointmentPage(item.appointment_id)}}},[_vm._v(" "+_vm._s(item.appointment_date)+" ")]):_vm._e()]}}])})],1)],1),_c('v-tab-item',{key:'supplier'},[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-1 mt-5",attrs:{"headers":_vm.reservedSupplierStockHeaders,"items":_vm.reservedSupplierStockTableItems,"hide-default-footer":true,"items-per-page":_vm.reservedSupplierStockTableItems.length,"sortable":false},scopedSlots:_vm._u([{key:"item.supplier",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.supplier.supplier_identifier)+" ")])]}},{key:"item.document_number",fn:function(ref){
              var item = ref.item;
return [_c('span',{style:({
                color: 'blue',
                cursor: 'pointer',
                textDecoration: 'underline',
              }),on:{"click":function($event){return _vm.navigateToOrdersPage(item.order_id)}}},[_vm._v(" "+_vm._s(item.identifier)+" ")])]}},{key:"item.short_name",fn:function(ref){
              var item = ref.item;
return [(item.short_name)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.short_name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.orderer_name))])])]:_vm._e()]}},{key:"item.appointment_date",fn:function(ref){
              var item = ref.item;
return [(item.appointment_date)?_c('span',{style:({
                color: 'blue',
                cursor: 'pointer',
                textDecoration: 'underline',
              }),on:{"click":function($event){return _vm.navigateToAppointmentPage(item.appointment_id)}}},[_vm._v(" "+_vm._s(item.appointment_date)+" ")]):_vm._e()]}},{key:"item.supplier_status",fn:function(ref){
              var item = ref.item;
return [(item && item.supplier && item.supplier.supplier_order_id)?_c('v-autocomplete',{staticClass:"mr-2",attrs:{"items":_vm.reception_order_statuses,"label":_vm.$t('receptions.status'),"item-value":"id","item-text":"text","return-object":""},on:{"change":function($event){return _vm.updateReceptionStatus(item)}},model:{value:(item.supplier.status_id),callback:function ($$v) {_vm.$set(item.supplier, "status_id", $$v)},expression:"item.supplier.status_id"}}):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }