<template>
  <v-row justify="center" class="mt-10">
    <v-col cols="12" sm="10" md="6" lg="4">
      <v-card ref="form" class="box-shad-none card-authenticated-wrapper">
        <v-card-text class="pl-0 pr-0 pt-0">
          <v-card-title class="pl-3 pr-3 mb-0 card-title-heading">
            <h3 class="text-white">{{ $t("forgotPassword.title") }}</h3>
          </v-card-title>
          <v-progress-linear
            :active="isLoading"
            indeterminate
            color="primary"
          ></v-progress-linear>
          <form class="pl-3 pr-3 pt-3">
            <div v-for="alert in alerts" :key="alert" :hidden="!alert.show">
              <v-alert :type="alert.type" dismissible>
                {{ alert.msg }}
              </v-alert>
            </div>
            <v-text-field
              v-model="email"
              :error-messages="emailErrors"
              :label="Email"
              required
              class="to-upper"
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
            ></v-text-field>
            <div class="text-right mb-5">
              <span class="forgot-password-txt"
                ><a href="/login">{{
                  $t("forgotPassword.backToLogin")
                }}</a></span
              >
            </div>
            <v-divider />
            <div class="mt-5">
              <v-btn class="mr-3 white--text" color="primary" @click="submit">
                {{ $t("resetPassword.submit") }}
              </v-btn>
              <v-btn @click="clear"> {{ $t("resetPassword.clear") }} </v-btn>
            </div>
          </form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from 'vuelidate/lib/validators'
import axios from '@/plugins/axios';
import { API_BASE_URL } from "@/config";
import router from '../../router'

export default {
  mixins: [validationMixin],
  validations: {
    email: { 
      required, 
        email: (val) => email((val ?? "").toLowerCase()),
    },
  },

  data: () => ({
    email: '',
    alerts: [],
    isLoading: false,
    // i18n
    Email: ''
  }),
  created () {
    this.Email = this.$t("forgotPassword.email");
  },
  computed: {
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Must be valid e-mail')
      !this.$v.email.required && errors.push('E-mail is required')
      return errors
    },
  },

  methods: {
    submit () {
      this.isLoading = true
      const payload = {
        email: this.email,
      }
      axios.post(API_BASE_URL + '/password/forgot', payload).then(fgpRes => {
        this.isLoading = false
        // this.alerts = []
        this.$store.state.alerts = []
        if (fgpRes.data.msg == 'Mail_sent_success') {
          // this.alerts.push({ msg: this.$t('forgotPassword.Mail_sent_success'), show: true, type: 'success' })

          this.$store.state.alerts.push({ msg: this.$t('forgotPassword.Mail_sent_success'), show: true, type: 'success' })
        } else {
          // this.alerts.push({ msg: fgpRes.data.msg, show: true, type: 'error' })

          this.$store.state.alerts.push({ msg: fgpRes.data.msg, show: true, type: 'error' })
        }
        this.$router.push('/login').catch(() => {});
        // this.alerts.forEach(alert => {
        //   setTimeout(() => {
        //     alert.show = false
        //   }, 5000);
        // });
        this.$store.state.alerts.forEach(alert => {
          setTimeout(() => {
            if (alert.type != 'error') {
              alert.show = false
            }
          }, 5000);
        });
      }).catch(error => {
        if (typeof error.response.data.errors == 'object') {
          this.alerts = []
          error.response.data.errors.forEach(error => {
            if (error == 'User_not_exist') {
              this.alerts.push({ msg: this.$t('forgotPassword.User_not_exist'), show: true, type: 'error' })
            } else if (error == 'email_required') {
              this.alerts.push({ msg: this.$t('forgotPassword.email_required'), show: true, type: 'error' })
            } else if (error == 'email_invalid') {
              this.alerts.push({ msg: this.$t('forgotPassword.email_invalid'), show: true, type: 'error' })
            } else {
              this.alerts.push({ msg: error, show: true, type: 'error' })
            }
          });
        } else {
          this.alerts.push({ msg: error.response.data.errors, show: true, type: 'error' })
        }
        this.alerts.forEach(alert => {
          setTimeout(() => {
            if (alert.type != 'error') {
              alert.show = false
            }
          }, 5000);
        });
      })
      .finally(() => {
        this.isLoading = false;
      })
    },
    clear () {
      this.$v.$reset()
      this.email = ''
    },
  },
}
</script>
<style scoped>
@import url("../../assets/CSS/authentication.css");
</style>
