var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{staticClass:"mr-3 to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('vehicles.search'),"autofocus":"","prepend-icon":"mdi-magnify","clearable":true,"clear-icon":"mdi-close","outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"input":_vm.debounceInput},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-data-table',{key:_vm.forceReloadComponentKey,staticClass:"elevation-0",staticStyle:{"width":"100%"},attrs:{"footer-props":{
          'items-per-page-options': _vm.dataTableOptions,
        },"fixed-header":"","headers":_vm.headers,"options":_vm.options,"server-items-length":_vm.totalItems,"loading-text":this.$t('loading'),"height":'calc(-200px + 100vh)',"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items-per-page":_vm.itemsPerPage,"item-key":"identifier","items":_vm.vehicles},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":[function($event){_vm.sortBy=$event},_vm.updateSort],"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":[function($event){_vm.sortDesc=$event},_vm.updateSortOrder],"update:page":_vm.updatePagination,"update:items-per-page":_vm.updateItemsPerPage},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('tr',{style:(_vm.determineRowBg(item.is_available))},[_c('td',{on:{"click":function($event){return _vm.editVehicle(item)}}},[_vm._v(" "+_vm._s(item.id)+" ")]),_c('td',{on:{"click":function($event){return _vm.editVehicle(item)}}},[(item.is_out_of_circulation)?[_c('v-tooltip',{attrs:{"top":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v(" mdi-car-arrow-right ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("vehicle_is_out_of_circulation"))+" ")])]:_vm._e()],2),_c('td',{on:{"click":function($event){return _vm.editVehicle(item)}}},[_vm._v(" "+_vm._s(item.registration_number)+" ")]),_c('td',{on:{"click":function($event){return _vm.editVehicle(item)}}},[(item.client)?_c('span',[_vm._v(" "+_vm._s(item.client.name)+" "+_vm._s(item.client.first_name ? item.client.first_name : ""))]):_vm._e()]),_c('td',{on:{"click":function($event){return _vm.editVehicle(item)}}},[(item.brand)?_c('span',[_vm._v(" "+_vm._s(item.brand.name)+" ")]):_vm._e()]),_c('td',{on:{"click":function($event){return _vm.editVehicle(item)}}},[_vm._v(" "+_vm._s(item.model)+" ")]),_c('td',{on:{"click":function($event){return _vm.editVehicle(item)}}},[_vm._v(" "+_vm._s(item.vin)+" ")])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }