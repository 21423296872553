export default {
    data(){
        return {
            showZippedReports: false,
        }
    },
    methods: {
        launchZippedReportModal() {
            this.showZippedReports = false;
            setTimeout(() => {
                this.showZippedReports = true;
            }, 10);
        },
    }
}