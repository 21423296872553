var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('label',{staticClass:"logoImage ma-4"},[_c('img',{staticStyle:{"width":"150px","height":"150px","border-radius":"50%","border":"3px dotted #efefef"},attrs:{"src":_vm.logoImage}}),_c('input',{ref:"files",staticClass:"d-none",attrs:{"type":"file","id":"files","multiple":""},on:{"change":function($event){return _vm.handleFilesUpload()}}})])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('users.name'),"error-messages":_vm.$store.state.validation && !_vm.$v.name.required
                                    ? [this.$t('users.text_messages.name_required')]
                                    : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"to-upper",attrs:{"disabled":true,"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('users.email'),"error-messages":_vm.$store.state.validation
                                    ? _vm.returnErrorMessage(
                                        [
                                            !_vm.$v.email.required,
                                            this.$t(
                                                'users.text_messages.email_required'
                                            ) ],
                                        [
                                            !_vm.$v.email.email,
                                            this.$t(
                                                'users.text_messages.invalid_email'
                                            ) ]
                                    )
                                    : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('users.password'),"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"error-messages":_vm.$store.state.validation
                                    ? _vm.returnErrorMessage(
                                        [
                                            !_vm.$v.password.required,
                                            this.$t(
                                                'users.text_messages.password_required'
                                            ) ],
                                        [
                                            !_vm.$v.password.minLength,
                                            this.$t(
                                                'users.text_messages.password_min_length'
                                            ) ]
                                    )
                                    : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-select',{attrs:{"autocomplete":_vm.autocompleteValueLocal,"items":_vm.languages,"item-value":"id","item-text":"text","label":_vm.$t('users.language'),"error-messages":_vm.$store.state.validation && !_vm.$v.language.required
                                    ? [
                                        this.$t(
                                            'users.text_messages.language_required'
                                        ) ]
                                    : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"ma-0 pa-0 ml-2"},[_c('strong',[_vm._v(_vm._s(_vm.$t('email_signature')))])])],1),_c('v-row',[_c('v-col',{attrs:{"id":"email_signature_profile"}},[_c('NoteEditor',{attrs:{"label":_vm.$t('email_signature'),"showLabel":false,"rows":5},model:{value:(_vm.email_signature),callback:function ($$v) {_vm.email_signature=$$v},expression:"email_signature"}})],1)],1)],1),_c('v-btn',{staticClass:"d-none",attrs:{"id":"submit-user-profile"},on:{"click":function($event){return _vm.onSubmit()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }