var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{staticClass:"mr-3 to-upper",attrs:{"label":_vm.$t('vehicles.search'),"autofocus":"","prepend-icon":"mdi-magnify","clearable":true,"clear-icon":"mdi-close"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.formattedEMails,"items-per-page":_vm.perPage,"server-items-length":_vm.total,"footer-props":{
          'items-per-page-options': _vm.perPageOptions,
        }},on:{"update:page":function (newPage) { _vm.page = newPage; _vm.getEmails(newPage)},"update:items-per-page":function (newPerPage) { _vm.perPage = newPerPage; _vm.getEmails(_vm.page)}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{class:{
          'bg-red': ['permanent_failure', 'temporary_failure'].includes(props.item.status.key),
          'bg-green': ['sent', 'delivered'].includes(props.item.status.key),
        },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handleRowClick(props.item)}}},[_c('td',[_vm._v(_vm._s(props.item.serial))]),_c('td',[_vm._v(_vm._s(props.item.title))]),_c('td',[_vm._v(_vm._s(props.item.from))]),_c('td',[_vm._v(_vm._s(props.item.to))]),_c('td',[_vm._v(_vm._s(props.item.subject))]),_c('td',[(_vm.isset(props.item.attachments))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',[_vm._v("mdi-attachment")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(props.item.attachments))])]):_vm._e()],1),_c('td',[_vm._v(_vm._s(props.item.date))]),_c('td',[_vm._v(_vm._s(props.item.status_text))])])]}}])}),_c('email-detail',{attrs:{"email-id":_vm.selectedEmailId},model:{value:(_vm.emailDialog),callback:function ($$v) {_vm.emailDialog=$$v},expression:"emailDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }