<template>
  <div>
    <v-row>
      <v-col
          md="2"
      >
        <v-menu
            v-model="monthPickerMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :value="formattedSelectedMonth"
                :label="translate('filter_options.select_month')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              type="month"
              v-model="selectedMonth"
              @input="monthPickerMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

    </v-row>
    <v-row>
      <v-col cols="6">
        <CompanyEmployeesInput :multiple="true" v-model="form.employees" :shifts_only="true"></CompanyEmployeesInput>
      </v-col>
      <v-col cols="3">
        <v-select
            :items="workingShifts"
            item-text="name"
            item-value="id"
            :label="translate('working_shift')"
            v-model="form.working_Shift"
        ></v-select>
      </v-col>
      <v-col cols="2">
        <v-select
            multiple
            chips
            :items="computeWeeksInMonth" item-text="label" :label="translate('select_week')" return-object
            v-model="form.weeks">
          <template v-slot:selection="data">
            <v-chip>
              {{ data.item.week_number }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
      <v-col cols="1">
        <span class="mt-6" @click="handleSubmit"><SaveButton class="ma-3"/></span>
      </v-col>
    </v-row>
    <v-simple-table :key="forceReloadComponentKey">
      <template v-slot:default>
        <thead>
        <tr>
          <th v-for="item in computeTableHeaders" :key="item.value"> {{ item.text }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="content in computeTableContent" :key="content.week.week_number">
          <td v-html="content.label"></td>
          <td v-for="(workingShift) in modifiedWorkingShifts" :key="workingShift.id">
            <template v-if="workingShift.type === 'record'">
            <span class="mx-2 my-2" v-for="data in getEmployeesWorkingInShiftAndTime(workingShift, content.week)"
                  @mouseenter="focused = data.employee_work_shift.id"
                  @mouseleave="focused = null"
                  :key="data.key"><v-chip class="ma-2" style="cursor: grab">{{ data.employee.name }}
              <v-btn icon v-show="focused === data.employee_work_shift.id"
                     @click="toggleDeleteConfirmation(data.employee_work_shift.id)"><v-icon
                  color="red">mdi-trash-can</v-icon></v-btn></v-chip>

            </span>
            </template>
            <template v-else> {{ getEmployeesWorkingInShiftAndTime(workingShift, content.week).length }} </template>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <confirmation-model
        :showDialog.sync="confirmationDialog"
        :text.sync="confirmationDialogConfirmationText"
        :trueText.sync="confirmationDialogTrueText"
        :falseText.sync="confirmationDialogFalseText"
        @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
    <DuplicateEmployeeWorkingShiftsModal
        v-model="duplicateEmployeeWorkingShiftsModal"
        :currentMonth="selectedMonth" :monthLabel="formattedSelectedMonth"
        @duplicated="(toMonth) => selectedMonth = toMonth"
    />
    <span @click="printHandler" id="print"></span>
    <span @click="duplicateHandler" id="duplicate"></span>
  </div>
</template>
<script>
import {API_BASE_URL} from "@/config";
import _axios from '@/plugins/axios';
import moment from "moment/moment";
import clockingMixin from "@/mixins/clockingMixin";
import CompanyEmployeesInput from "@/components/CompanyEmployeesInput.vue";
import SaveButton from "@/components/Buttons/SaveButton.vue";
import ConfirmationModel from "@/components/ConfirmationModel.vue";
import DuplicateEmployeeWorkingShiftsModal from "@/components/Clocking/DuplicateEmployeeWorkingShiftsModal.vue";

export default {
  name: 'EmployeeWorkingShiftsListing',
  mixins: [clockingMixin],
  components: {DuplicateEmployeeWorkingShiftsModal, SaveButton, CompanyEmployeesInput, ConfirmationModel},
  data() {
    return {
      workingShifts: [],
      employeeWorkingShifts: [],
      employeeWorkingShiftDialog: false,
      selectedEmployeeWorkingShift: null,
      selectedMonth: null,
      monthPickerMenu: false,
      startDatePicker: false,
      endDatePicker: false,
      focused: null,
      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperationId: null,
      duplicateEmployeeWorkingShiftsModal: false,
      form: {
        employees: [],
        weeks: null,
        working_Shift: null,
      },

    }
  },
  async mounted() {
    await this.getWorkingShifts();
    this.employees = await this.getEmployees();
    this.selectedMonth = moment().format('YYYY-MM')
    await this.getEmployeeWorkingShifts();
  },
  computed: {
    formattedSelectedMonth() {
      if (!this.selectedMonth) return '';
      return moment(this.selectedMonth).locale(this.$store.state.languageId).format('MMMM YYYY')
    },
    beginningOfMonth() {
      if (!this.selectedMonth) return '';
      return moment(this.selectedMonth).startOf('month').format('YYYY-MM-DD')
    },
    endOfMonth() {
      if (!this.selectedMonth) return '';
      return moment(this.selectedMonth).endOf('month').format('YYYY-MM-DD')
    },
    computeWeeksInMonth() {
      if (!this.selectedMonth) return [];
      const weeks = []

      const startOfMonth = moment(this.selectedMonth).startOf('month')
      const endOfMonth = moment(this.selectedMonth).endOf('month')
      let currentWeek = startOfMonth.clone().startOf('week')
      while (currentWeek.isBefore(endOfMonth)) {
        let week = {
          start_date: currentWeek.format('YYYY-MM-DD'),
          end_date: currentWeek.clone().endOf('week').format('YYYY-MM-DD'),
          week_number: currentWeek.week(),
          year: currentWeek.year(),
        }
        week.label_short = `${this.translate('week')} ${week.week_number}`;
        week.label = `${this.translate('week')} ${week.week_number},
          ${moment(week.start_date).format("MM/DD")} - ${moment(week.end_date).format("MM/DD")}`;

        weeks.push(week)
        currentWeek.add(1, 'week')
      }

      return weeks
    },
    modifiedWorkingShifts() {
      let response = [];

      this.workingShifts.forEach((item) => {
        item.type = 'record'
        response.push(item)


        response.push({
          ...item,
          type: 'total',
        })
      })

      return response
    },
    computeTableHeaders() {
      let headers = [
        {
          text: this.translate('weeks'),
          sortable: false,
          value: 'weeks',
        },
      ]
      this.workingShifts.forEach((item) => {
        headers.push({
          text: `${item.name} - ${this.formatTimeWithLocale(item.start_time)} - ${this.formatTimeWithLocale(item.end_time)}`,
          value: item.id,
          sortable: false,
        })

        // add totals column
        headers.push({
          text: this.translate('total'),
          value: item.id + '_total',
          sortable: false,
          width: 30
        })
      })

      return headers
    },
    computeTableContent() {
      return this.computeWeeksInMonth.map((week) => {
        return {
          week,
          label: `${this.translate('week')} ${week.week_number}
          <br> ${this.formatDateWithLocale(week.start_date, true)} - ${this.formatDateWithLocale(week.end_date, true)}`,
        }
      })
    },
  },
  watch: {
    "form.start_date": {
      handler() {
        if (this.form.end_date && moment(this.form.start_date).isAfter(this.form.end_date)) {
          this.form.end_date = null
        }
      }
    },
    selectedMonth(){
      this.getEmployeeWorkingShifts()
    }
  },
  methods: {
    getWorkingShifts() {
      return new Promise((resolve, reject) => {
        _axios.get(API_BASE_URL + '/employees/working_shifts')
            .then(response => {
              this.workingShifts = response.data.data
              resolve()
            })
            .catch(error => {
              reject(error)
            })
      })
    },
    getEmployeeWorkingShifts() {
      return new Promise((resolve, reject) => {
        let url = API_BASE_URL + '/employees/employees_working_shifts';

        if (this.selectedMonth) {
          let startDate = moment(this.selectedMonth).startOf('month').startOf('week').format('YYYY-MM-DD')
          let endDate = moment(this.selectedMonth).endOf('month').endOf('week').format('YYYY-MM-DD')
          url += `?start_date=${startDate}&end_date=${endDate}`
        }

        _axios.get(url)
            .then(response => {
              this.employeeWorkingShifts = response.data.data
              resolve()
            })
            .catch(error => {
              reject(error)
            })
      })
    },
    getEmployeesWorkingInShiftAndTime(shift, week) {
      // with moment get beginning and end of week

      let startOfWeek = week.start_date;
      let endOfWeek = week.end_date;
      return this.employeeWorkingShifts.filter((item) => {
        let startDate = moment(item.start_date).format('YYYY-MM-DD');
        let endDate = moment(item.end_date).format('YYYY-MM-DD');

        return item.working_shift_id === shift.id
            && (
                moment(startDate).isSameOrAfter(startOfWeek) && moment(endDate).isSameOrBefore(endOfWeek)
            )
      }).map(item => {
        return {
          key: item.id,
          employee: this.employees.find(emp => emp.id === item.employee_id),
          shift: this.workingShifts.find(s => s.id === item.working_shift_id),
          employee_work_shift: item,
        }
      })
    },
    translate(key) {
      return this.$t('clocking_section.' + key)
    },
    handleSubmit() {
      for (const key in this.form) {
        if (!this.isset(this.form[key])) {
          return this.$toast.error(this.translate('fill_all_field'))
        }
      }

      let form = this.clone(this.form)
      form.employees = form.employees.map(e => e.value)
      form.month = this.selectedMonth
      // form.start_date = form.week.start_date
      // form.end_date = form.week.end_date
      // delete (form.week)

      _axios.put(API_BASE_URL + `/employees/working_shifts/${this.form.working_Shift}/employees`, form)
          .then(({data}) => {

            data = data.data
            this.employeeWorkingShifts = [...this.employeeWorkingShifts, ...data.shifts]
            this.forceReloadComponent()
            if (this.isset(data.shifts)) {
              this.$toast.success(this.translate("employees_shift_added"))
            }

            let overlaps = data.overlaps;

            if (this.isset(overlaps)) {
              let employeeNames = overlaps.map(id => this.employees.find(e => e.id === id).name).join(", ");
              return this.$toast.error(employeeNames + ' ' + this.translate('found_in_overlapping_shifts'))
            }

          })
    },
    toggleDeleteConfirmation(employeeWorkingShiftId) {
      this.confirmationDialog = true;
      this.confirmationDialogConfirmationText = this.translate('delete_confirmation_text');
      this.confirmationDialogFalseText = this.translate('no');
      this.confirmationDialogTrueText = this.translate('yes');
      this.confirmationDialogButtonClickedIs = 'delete';
      this.confirmationDialogOperationId = employeeWorkingShiftId;
    },
    confirmationButtonClicked(action) {
      if (action === true && this.confirmationDialogButtonClickedIs === 'delete') {
        this.handleDelete(this.confirmationDialogOperationId)
      }
    },
    printHandler() {
      const url = API_BASE_URL + `/employees/employees_working_shifts/print?start_date=${this.beginningOfMonth}&end_date=${this.endOfMonth}`;
      const fileName = 'time_table.pdf';
      this.setPreviewData(url, fileName, true, 'application/pdf');
    },
    duplicateHandler() {
      this.duplicateEmployeeWorkingShiftsModal = false;
      setTimeout(() => {
        this.duplicateEmployeeWorkingShiftsModal = true;
      }, 10)
    },
    handleDelete(employeeWorkingShiftId) {
      _axios.delete(API_BASE_URL + `/employees/employees_working_shifts/${employeeWorkingShiftId}`)
          .then(() => {
            this.employeeWorkingShifts = this.employeeWorkingShifts.filter(item => item.id !== employeeWorkingShiftId)
            this.forceReloadComponent()
            this.$toast.success(this.translate('employee_shift_deleted'))
          })
    }
  }
}
</script>