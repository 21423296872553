<template>
  <div>
    <v-dialog :value="value" max-width="300px" scrollable>
      <v-card>
        <v-card-title>
          {{ $t('batch_printing')}}
          <v-spacer/>
          <v-btn icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item v-for="page in pages" :key="page.page">
              <v-list-item-content>
                <v-list-item-title>
                  <v-btn text @click="printLabels(page)">{{ $t('page') }} {{ page.page }} ({{page.start}} - {{page.end}})</v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <span @click="close"> <CloseButton/> </span>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CloseButton from "@/components/Buttons/CloseButton.vue";
import {API_BASE_URL} from "@/config";

export default {
  name: 'BatchPrinting',
  components: {CloseButton},
  emits: ['input'],
  props: {
    depotBulkOperation: {
      default: null
    },
    type: {
      default: 'bulk_operation_labels'
    },
    value: {
      default: false
    },
    chunkSize: {
      default: 100
    }
  },
  data() {

  },
  computed: {
    pages() {
      if (!this.depotBulkOperation) return [];

      let pages = [];
      let pageSize = Math.ceil(this.depotBulkOperation.completed.length / this.chunkSize);
      let remaining = this.depotBulkOperation.completed.length;
      if(remaining < this.chunkSize){
        pages.push({
          page: 1,
          size: remaining,
          start: 1,
          end: remaining
        })

        return pages;
      }

      for (let i = 0; i < pageSize; i++) {
        let currentPage = i + 1;
        let currentSize = remaining > this.chunkSize ? this.chunkSize : remaining;

        if(currentSize < 10){
          if(pages.length > 0) {
            pages[pages.length - 1].end = pages[pages.length - 1].end + currentSize;
            pages[pages.length - 1].size = pages[pages.length - 1].end + currentSize;
          }else{
            return [{
              page: currentPage,
              size: currentSize,
              start: (currentPage - 1) * this.chunkSize + 1,
              end: (currentPage - 1) * this.chunkSize + currentSize
            }];
          }
          break;
        }

        pages.push({
          page: currentPage,
          size: currentSize,
          start: (currentPage - 1) * this.chunkSize + 1,
          end: (currentPage - 1) * this.chunkSize + currentSize
        });

        remaining -= currentSize;
      }

      return pages;
    }
  },
  methods: {
    close() {
      this.$emit('input', false)
    },
    printLabels(payload) {
      let url = API_BASE_URL + "/depot/" + this.depotBulkOperation.id + "/print?document="+this.type+"&lang=" + (localStorage.getItem("languageId") || "en") + "&user=" + localStorage.getItem("loginUserId")
      url+= "&start=" + payload.start + "&end=" + payload.end + "&size=" + payload.size;

      let fileName = this.depotBulkOperation.depot_manager
      this.setPreviewData(url, fileName, true, 'application/pdf');
    },
  }
}
</script>

<style scoped>

</style>