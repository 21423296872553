import Vue from "vue";
import Vuex from "vuex";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { API_BASE_URL } from "./config";
import i18n from "./i18n";
import VueNoty from "vuejs-noty";
import Helper from "./helpers.js";
import GlobalComputed from "./GlobalComputed.js";
import wysiwyg from "vue-wysiwyg";
import "vue-wysiwyg/dist/vueWysiwyg.css";
import "./assets/CSS/print.min.css";
import "./assets/CSS/extra-ui.css";
import "vuejs-noty/dist/vuejs-noty.css";


import "./plugins/vuetify-mask.js";
import 'vue-tel-input-vuetify/lib/sprite.css'
import VueLazyload from 'vue-lazyload'
import VueApexCharts from 'vue-apexcharts'

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
Vue.use(VueApexCharts)

Vue.use(VueLazyload)

Vue.use(VueNoty);
Vue.mixin(Helper);
Vue.mixin(GlobalComputed);

Vue.component('apexchart', VueApexCharts)

const toastOptions = {
    // toast options
    position: 'bottom-left',
    transition: "Vue-Toastification__bounce",
    maxToasts: 28,
    newestOnTop: true,
    shareAppContext: true
};
Vue.use(Toast, toastOptions);

Vue.use(VueCookies);
Vue.use(wysiwyg, {
  hideModules: {
    image: true,
    link: true,
    code: true,
    table: true,
    removeFormat: true,
  },
});

// Vue-Cookies
import VueCookies from "vue-cookies";

Vue.config.productionTip = false;
Vue.config.baseUrl = API_BASE_URL;
// Need to make this value to false later, used for testing purpose
// Vue.config.devtools = true;

export var app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  Vuex,
  data() {
    return {};
  },
  render: (h) => h(App),
}).$mount("#app");
