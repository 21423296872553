<template>
    <v-dialog 
        max-width="1200" 
        :value="value" 
        v-if="isset(order)" 
        persistent
        @click:outside="close()"
        @keydown.esc="close()"
    >
        <v-card>
            <v-card-title> {{ $t('order_email_history') }} </v-card-title>
            <v-card-text>
                <EmailTable :items="order.email_history" />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <span @click="close">
                    <CloseButton/>
                </span>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios';
import { API_BASE_URL } from '@/config';
import EmailTable from "@/components/Emails/EmailTable.vue";
import CloseButton from "@/components/Buttons/CloseButton.vue";
export default {
    name: "OrderEmailHistory",
    components: {CloseButton, EmailTable},
    props:{
        order: {
            type: Object,
            required: true
        },
        value: {
            type: Boolean,
            required: true
        }
    },
    data(){
        return {
            history: [],
            axiosHeaders: {
                'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
            }
        }
    },
    watch:{
        async value(val){
            if(!val || !this.order){
                return null;
            }

            if(this.isset(this.order)){
                //
            }
        }
    },
    methods:{
        close(){
            this.$emit('input', false);
        },
        getEmailHistory(billId){
            return new Promise((resolve, reject) => {
                return axios.get(API_BASE_URL + '/bills/' + billId, {headers: this.axiosHeaders})
                    .then(response => {
                    resolve(response.data.data)
                    })
            })
        }
    }
}
</script>

<style scoped>

</style>