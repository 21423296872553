<template>
  <div>
    <v-dialog max-width="800" :value="value">
      <v-card>
        <v-form @submit.prevent="saveHandler()">

          <v-card-title>
            <span class="headline">{{ this.translate(  (workingShift ? 'add' : 'edit')+'_working_shift') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      class="to-upper"
                      v-model="name"
                      label="Name"
                      :required="true"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                      type="time"
                      v-model="start_time"
                      label="Start Time"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                      v-model="end_time"
                      type="time"
                      label="End Time"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                      type="time"
                      v-model="start_break_time"
                      label="Start Break Time"
                      required
                  ></v-text-field>
                </v-col>
<!--                <v-col cols="12">-->
<!--                  <CompanyEmployeesInput-->
<!--                      v-model="selectedEmployees"-->
<!--                      :label="$t('clocking_section.select_employees')"-->
<!--                      multiple-->
<!--                  />-->
<!--                </v-col>-->
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <span @click="close()" class="mr-2"> <CloseButton/></span>
            <span> <SaveButton/> </span>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CloseButton from "@/components/Buttons/CloseButton.vue";
import SaveButton from "@/components/Buttons/SaveButton.vue";
// import CompanyEmployeesInput from "@/components/CompanyEmployeesInput.vue";
import axios from "axios";
import {API_BASE_URL} from "@/config";
import clockingMixin from "@/mixins/clockingMixin";
import moment from "moment";
export default {
  name: 'WorkingShiftForm',
  emits: ['input', 'created', 'updated', 'deleted'],
  mixins: [clockingMixin],
  components: {SaveButton, CloseButton},
  props: {
    value: {
      type: Boolean,
      default: false
    },
    workingShift: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      name: '',
      start_time: '',
      end_time: '',
      start_break_time: '',
      selectedEmployees: [],
      id: null,
    }
  },
  watch: {
    async value(val) {
      if (!val) return
      this.resetForm()

      if (this.workingShift) {

        this.id = this.workingShift.id
        this.name = this.workingShift.name
        this.start_time = this.workingShift.start_time
        this.end_time = this.workingShift.end_time
        this.start_break_time = this.workingShift.start_break
        this.selectedEmployees = await this.getWorkingShiftEmployees();
      }
    },
  },
  methods: {
    close() {
      this.$emit('input')
    },
    payload() {
      return {
        name: this.name,
        start_time: moment(this.start_time, 'HH:mm').format('HH:mm'),
        end_time: moment(this.end_time, 'HH:mm').format('HH:mm'),
        start_break: this.start_break_time ? moment(this.start_break_time, 'HH:mm').format('HH:mm') : null,
        employee_ids: this.selectedEmployees.map(i => i.value)
      }
    },
    getWorkingShiftEmployees() {
      return new Promise((resolve, reject) => {
        axios.get(`${API_BASE_URL}/employees/working_shifts/${this.id}/employees`, {headers: this.axiosOptions})
            .then(response => {
              resolve(response.data.data.map(employee => ({
                    value: employee.id,
                    text: employee.name
                  }))
              );
            })
            .catch(error => {
              reject(error)
            })
      })
    },
    saveHandler() {
      if (!this.name || !this.start_time || !this.end_time) {
        return this.$toast.error(this.translate('please_fill_all_fields'))
      }
      if (this.id) {
        this.updateHandler()
      } else {
        this.createHandler()
      }
    },
    createHandler() {
      this.$store.state.isLoading = true
      axios.post(`${API_BASE_URL}/employees/working_shifts`, this.payload(), {headers: this.axiosOptions})
          .finally(() => this.$store.state.isLoading = false )
          .then(response => {
            this.$emit('created', response.data.data)
            this.$toast.success(this.translate('working_shift_added_successfully'))
          })
          .catch(error => {
            console.log(error)
          })
    },
    updateHandler() {
      this.$store.state.isLoading = true
      axios.put(`${API_BASE_URL}/employees/working_shifts/${this.id}`, this.payload(), {headers: this.axiosOptions})
          .finally(() => this.$store.state.isLoading = false)
          .then(response => {
            this.$emit('updated', response.data.data)
            this.$toast.success(this.translate('working_shift_updated_successfully'))
          })
          .catch(error => {
            console.log(error)
          })
    },
    resetForm() {
      this.name = ''
      this.start_time = ''
      this.end_time = ''
      this.start_break_time = ''
      this.selectedEmployees = []
    }
  }
}
</script>