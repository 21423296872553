import Vue from "vue";
import Vuex from "vuex";
import axios from "@/plugins/axios";
import { API_BASE_URL } from "../config";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // form statuess
    inViewMode: false,
    inAddMode: false,
    inEditMode: false,
    inDeleteMode: false,
    inDetailsMode: false,
    inJustCancelMode: false,
    inUpdateMode: false,
    inBillingMode: false,
    inReportMode: false,
    inExpeditionMode: false,
    inExpeditionListingMode: false,
    inRecurrenceMode: false,
    inSchedulerMode: false,
    inSchedulerEditMode: false,
    inSchedulerListingMode: false,
    inReceptionCreateMode: false,
    inStockListingMode: false,
    inTyreDepotMode: false,
    inExpenseMode: false,
    inExpenseListingMode: false,
    inBillingListingMode: false,
    colorCodes: {
      success: "#d1e7dd",
      danger: "#f9d7da",
      warning: "#fff4cd",
      primary: "#cfe2ff",
      secondary: "#e2e2e2",
      info: "cff5fd",
      light: "#f8fafa",
      dark: "#202528",
    },

    // show side nav drawer
    showNavDrawer: true,

    // add button route
    addButtonRoute: "",

    // global search
    search: "",

    // Access_Token
    AccessToken: localStorage.getItem("accessToken") || "",

    // Role Details
    role: null,

    // Duplicate Role
    DuplicateRole: localStorage.getItem("DuplicateRole")
      ? JSON.parse(localStorage.getItem("DuplicateRole"))
      : null,

    // CompanyID
    companyId: localStorage.getItem("companyID") || "",
    loginUser: localStorage.getItem("loginUser") || "",
    languageId: localStorage.getItem("languageId") || "",
    currencyId: localStorage.getItem("currencyId") || "",
    isLoading: false,

    // billing module
    billType: null,
    billTypes: [],
    expensesType: null,
    expensesTypes: [],
    billCancelVisible: false,
    showPrintReportInPrintDropDown: false,
    showPrintDepotOutReportInPrintDropDown: false,
    isEditingAllowed: false,
    makePurchaseOrder: false,
    makeBillVisible: false,
    makeCreditNote: false,
    showNewButton: false,
    isBillSelected: false,
    selectedBillData: null,
    isExpenseSelected: false,
    selectedExpenseData: null,
    showSaveButton: false,
    validation: false,
    editId: null,
    selectedExpeditionIdsForBilling: null,
    selectedExpedition: null,
    selectedExpeditionStatus: null,
    selectedExpeditionDocuments: [],
    selectedTransporter: null,
    selectedExpeditor: null,

    searchClientValue: null,


    selectedItemsForBulkPayment: null,

    orderReminderTypes: [],

    // Billing and Scheduler Module
    showDepotButtons: 0,
    schedulerDate: null,

    // appointment
    appointment: null,
    appointmentUpdated: null,
    appointmentStatuses: [],

    countries: [],
    currencies: [],
    topCurrencyType: null,
    bankAccounts: [],
    defaultBankAccount: null,
    topBankAccount: null,

    // Global Variables
    cachedAdressTypes: [],
    taxationReasons: [],
    familyArticles: [],
    approvals: [],
    tyreTypes: [],
    projectStatuses: [],
    wheelTypes: [],
    wheelMaterials: [],
    tyreSeasons: [],
    resourceTypes: [],
    statuses: [],
    familyClients: [],
    refreshList: false,
    storedworkresources: [],
    workresources: [],

    navigationData: [],

    attachmentCounts: [],
    vehicleId: null,

    alerts: [],

    cachedData: {
      brands: {},
      suppliers: {},
    },

    addRentalVehicleFormActive: false,
    rentalId: null,
    vehicles: [],
    selectedArticlePendingAppointment: null,
    applySelectedArticlePendingAppointmentKey: null,
    showApplySelectedArticleButton: false,
    companyLanguages: [],
    forceReloadComponentKey: null,
    apiAbortControllerMap: {},
    repeatedArticles: [],
    currentlySelectedClient: null,
    vehicleDataForAppBar: null,

    currentBillPeppolStatus: null,
    companyApplicationResources: [],
    totalUnreadMessagesCount: 0,
    
    previewUrl: null,
    previewFileName: null,
    previewIsAuthDependent: false,
    mimeType: null,
    isShowFullScreen: null,
    openInNewWindow: null,

    reportViewData: false,
    functionsToExecuteAgain: [],

    classCodes: {},

    getMessageInterval: null,
    tyreDepotIsActive: false,
    workSheetIsActive: false,
    editVehicleModalKey:0,
    pdfMailConfig: {},
    orderCounter:{
      data: null,
      order_detail_key: null
    },
    mapApiKey: null,
    remount: true,
    topSubmitLoading: false,
    progressBarLoading: false,
    loadingTimeout: null,
    historyTableModel: null,
    historyTableModelId: null,
    newConnectionId: null,
    dynamicLoadedComponents: [],
    copiedAppointment: null,
    exportStatus: null,
    currentPageData: null
  },
  mutations: {
    setCompanyApplicationResources(state, companyApplicationResources) {
        state.companyApplicationResources = companyApplicationResources;
    },
    /**
     * Put form in edit or view mode
     * @param state
     * @param mode (128|64|32|16|8|4|2|1) in binary
     */
    setFormMode(state, mode) {
      const binValue = mode.padStart(9, "0");
      // DEAV (Delete, Edit, Add, View) = (128|64|32|16|8|4|2|1)

      function checkTruePositionsExcept(binValue, exceptPositions) {
        for (var i = 0; i < binValue.length; i++) {
          let positionNotToCheck;
          for (var j = 0; j < exceptPositions.length; j++) {
            if (i == exceptPositions[j]) {
              positionNotToCheck = i;
            }
          }

          if (binValue.charAt(i) == "1" && positionNotToCheck == undefined) {
            return true;
          }
        }
        return false;
      }

      state.inViewMode =
        !!parseInt(binValue[8]) &&
        checkTruePositionsExcept(binValue, [8]) == false;
      state.inAddMode =
        !!parseInt(binValue[7]) &&
        checkTruePositionsExcept(binValue, [7]) == false;
      state.inUpdateMode =
        !!parseInt(binValue[8]) &&
        !!parseInt(binValue[7]) &&
        checkTruePositionsExcept(binValue, [8, 7]) == false;
      state.inEditMode =
        !!parseInt(binValue[6]) &&
        checkTruePositionsExcept(binValue, [6]) == false;
      state.inDeleteMode =
        !!parseInt(binValue[5]) &&
        checkTruePositionsExcept(binValue, [5]) == false;
      state.inDetailsMode =
        !!parseInt(binValue[4]) &&
        checkTruePositionsExcept(binValue, [4]) == false;
      state.inJustCancelMode =
        !!parseInt(binValue[8]) &&
        !!parseInt(binValue[7]) &&
        !!parseInt(binValue[6]) &&
        checkTruePositionsExcept(binValue, [8, 7, 6]) == false;

      state.inBillingMode =
        !!parseInt(binValue[3]) &&
        checkTruePositionsExcept(binValue, [3]) == false;
      state.inSchedulerMode =
        !!parseInt(binValue[3]) &&
        !!parseInt(binValue[4]) &&
        checkTruePositionsExcept(binValue, [3, 4]) == false;
      state.inSchedulerEditMode =
        !!parseInt(binValue[3]) &&
        !!parseInt(binValue[4]) &&
        !!parseInt(binValue[5]) &&
        checkTruePositionsExcept(binValue, [3, 4, 5]) == false;
      state.inSchedulerListingMode =
        !!parseInt(binValue[3]) &&
        !!parseInt(binValue[4]) &&
        !!parseInt(binValue[5]) &&
        !!parseInt(binValue[6]) &&
        checkTruePositionsExcept(binValue, [3, 4, 5, 6]) == false;
      state.inReceptionCreateMode =
        !!parseInt(binValue[3]) &&
        !!parseInt(binValue[8]) &&
        checkTruePositionsExcept(binValue, [3, 8]) == false;
      state.inStockListingMode =
        !!parseInt(binValue[0]) &&
        !!parseInt(binValue[1]) &&
        checkTruePositionsExcept(binValue, [0, 1]) == false;
      state.inTyreDepotMode =
        !!parseInt(binValue[0]) &&
        !!parseInt(binValue[1]) &&
        !!parseInt(binValue[2]) &&
        checkTruePositionsExcept(binValue, [0, 1, 2]) == false;

      state.inRecurrenceMode =
        !!parseInt(binValue[8]) &&
        !!parseInt(binValue[7]) &&
        !!parseInt(binValue[6]) &&
        !!parseInt(binValue[5]) &&
        checkTruePositionsExcept(binValue, [8, 7, 6, 5]) == false;
      
        state.inReportMode =
        !!parseInt(binValue[8]) &&
        !!parseInt(binValue[7]) &&
        !!parseInt(binValue[6]) &&
        !!parseInt(binValue[5]) &&
        !!parseInt(binValue[4]) &&
        checkTruePositionsExcept(binValue, [8, 7, 6, 5, 4]) == false;
    
        state.inExpeditionMode =
        !!parseInt(binValue[8]) &&
        !!parseInt(binValue[7]) &&
        !!parseInt(binValue[6]) &&
        !!parseInt(binValue[5]) &&
        !!parseInt(binValue[4]) &&
        !!parseInt(binValue[3]) &&
        checkTruePositionsExcept(binValue, [8, 7, 6, 5, 4, 3]) == false;

        state.inExpeditionListingMode =
        !!parseInt(binValue[7]) &&
        !!parseInt(binValue[6]) &&
        !!parseInt(binValue[5]) &&
        !!parseInt(binValue[4]) &&
        !!parseInt(binValue[3]) &&
        checkTruePositionsExcept(binValue, [7, 6, 5, 4, 3]) == false;

        state.inExpenseMode = 
        !!parseInt(binValue[8]) &&
        !!parseInt(binValue[7]) &&
        !!parseInt(binValue[6]) &&
        !!parseInt(binValue[5]) &&
        !!parseInt(binValue[4]) &&
        !!parseInt(binValue[3]) &&
        !!parseInt(binValue[2]) &&
        checkTruePositionsExcept(binValue, [8, 7, 6, 5, 4, 3, 2]) == false;
        
        state.inExpenseListingMode = 
        !!parseInt(binValue[7]) &&
        !!parseInt(binValue[6]) &&
        !!parseInt(binValue[5]) &&
        !!parseInt(binValue[4]) &&
        !!parseInt(binValue[3]) &&
        !!parseInt(binValue[2]) &&
        checkTruePositionsExcept(binValue, [7, 6, 5, 4, 3, 2]) == false;
        
        state.inBillingListingMode =
        !!parseInt(binValue[3]) &&
        !!parseInt(binValue[4]) &&
        !!parseInt(binValue[8]) &&
        checkTruePositionsExcept(binValue, [3, 4, 8]) == false;
        
    
    },
    setPdfMailConfig(state, pdfMailConfig){
        state.pdfMailConfig = pdfMailConfig;
    },
    setSearchClientValue(state, searchClientValue){
        state.searchClientValue = searchClientValue;
    },
    setMapApiKey(state, mapApiKey){
        state.mapApiKey = mapApiKey;
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    invertNavDrawerVisibility(state) {
      state.showNavDrawer = !state.showNavDrawer;
    },
    setAddButtonRoute(state, route) {
      state.addButtonRoute = route;
    },
    setGlobalSearch(state, search) {
      state.search = search;
    },
    setRemount(state, remount){
        state.remount = remount;
    },
    setBankAccount(state, topBankAccount) {
      state.topBankAccount = topBankAccount;
      if(topBankAccount.currency){
        state.topCurrencyType = topBankAccount.currency;
      }
    },
    setFunctionsToExecuteAgain(state, functionsToExecuteAgain){
      state.functionsToExecuteAgain = functionsToExecuteAgain;
    },
    setGetMessageInterval(state, getMessageInterval){
      state.getMessageInterval = getMessageInterval;
    },
    setTopCurrencyType(state, topCurrencyType) {
      state.topCurrencyType = topCurrencyType;
    },
    setBillType(state, billType) {
      state.billType = billType;
    },
    setBillTypes(state, billTypes) {
      state.billTypes = billTypes;
    },
    setExpensesType(state, expensesType) {
      state.expensesType = expensesType;
    },
    setExpensesTypes(state, expensesTypes) {
      state.expensesTypes = expensesTypes;
    },
    setReportViewData(state, reportViewData){
      state.reportViewData = reportViewData;
    },
    setPreviewUrl(state, previewUrl ) {
      state.previewUrl = previewUrl;
    },
    setPreviewFileName(state, previewFileName){
      state.previewFileName = previewFileName;
    },
    setPreviewIsAuthDependent(state, previewIsAuthDependent){
      state.previewIsAuthDependent = previewIsAuthDependent
    },
    setPreviewMimeType(state, mimeType){
      state.mimeType = mimeType;
    },
    setIsShowFullScreen(state, isShowFullScreen){
      state.isShowFullScreen = isShowFullScreen;
    },
    setOpenInNewWindow(state, openInNewWindow){
        state.openInNewWindow = openInNewWindow;
      },
    setBillCancelVisible(state, isVisible) {
      state.billCancelVisible = isVisible;
    },
    setShowPrintReportInPrintDropDown(state, isVisible) {
      state.showPrintReportInPrintDropDown = isVisible;
    },
    setShowPrintDepotOutReportInPrintDropDown(state, isVisible) {
        state.showPrintDepotOutReportInPrintDropDown = isVisible;
    },
    setMakePurchaseOrderVisible(state, isVisible) {
      state.makePurchaseOrder = isVisible;
    },
    setMakeBillVisible(state, isVisible) {
      state.makeBillVisible = isVisible;
    },
    setMakeCreditNoteVisible(state, isVisible) {
      state.makeCreditNote = isVisible;
    },
    setShowNewButton(state, isVisible) {
      state.showNewButton = isVisible;
    },
    setShowSaveButton(state, isVisible) {
      state.showSaveButton = isVisible;
    },
    setIsBillSelected(state, isSelected) {
      state.isBillSelected = isSelected;
    },
    setSelectedBillData(state, data) {
      if (data) {
        state.selectedBillData = data;
      } else {
        state.selectedBillData = null;
      }
    },
    setIsExpenseSelected(state, isSelected) {
      state.isExpenseSelected = isSelected;
    },
    setSelectedExpenseData(state, data) {
      if (data) {
        state.selectedExpenseData = data;
      } else {
        state.selectedExpenseData = null;
      }
    },
    setValidation(state, onOrOff) {
      state.validation = onOrOff;
    },
    seteditId(state, billId = null) {
      state.editId = billId;
    },
    setSelectedExpeditionIdsForBilling(state, selectedExpeditionIdsForBilling = null) {
      state.selectedExpeditionIdsForBilling = selectedExpeditionIdsForBilling;
    },
    setSelectedItemsForBulkPayment(state, selectedItemsForBulkPayment = null){
        state.selectedItemsForBulkPayment = selectedItemsForBulkPayment;
    },
    setSelectedExpedition(state, selectedExpedition) {
      state.selectedExpedition = selectedExpedition;
    },
    setSelectedExpeditionStatus(state, selectedExpeditionStatus){
      state.selectedExpeditionStatus = selectedExpeditionStatus;
    },
    setSelectedExpeditionDocuments(state, selectedExpeditionDocuments){
        state.selectedExpeditionDocuments = selectedExpeditionDocuments;
    },
    setSelectedTransporter(state, selectedTransporter){
        state.selectedTransporter = selectedTransporter;
    },
    setSelectedExpeditor(state, selectedExpeditor){
        state.selectedExpeditor = selectedExpeditor;
    },
    setVehicleId(state, vehicleId = null) {
      state.vehicleId = vehicleId;
    },
    setShowDepotButtons(state, visibleStatus) {
      state.showDepotButtons = visibleStatus;
    },
    setAppointmentRedirectData(state, appointment) {
      state.appointment = appointment;
    },
    setEditingAllowed(state, allowed) {
      state.isEditingAllowed = allowed;
    },
    setSchedulerDate(state, date) {
      state.schedulerDate = date;
    },
    setVehicleDataForAppBar(state, vehicleDataForAppBar) {
        state.vehicleDataForAppBar = vehicleDataForAppBar;
    },
    setTopSubmitLoading(state, topSubmitLoading){
        state.topSubmitLoading = topSubmitLoading;
    },
    setProgressBarLoading(state, progressBarLoading){
        state.progressBarLoading = progressBarLoading;
    },
    setLoadingTimeout(state, timeout) {
        state.loadingTimeout = timeout;
    },
    clearLoadingTimeout(state) {
        if (state.loadingTimeout) {
            clearTimeout(state.loadingTimeout);
            state.loadingTimeout = null;
        }
    },
    setCurrentPageData(state, currentPageData){
        state.currentPageData = currentPageData;
    },
    // Set Global variable
    setCachedAdressTypes(state, cachedAdressTypes) {
      state.cachedAdressTypes = cachedAdressTypes;
    },
    setTaxationReasons(state, taxationReasons) {
      state.taxationReasons = taxationReasons;
    },
    setFamilyArticles(state, familyArticles) {
      state.familyArticles = familyArticles;
    },
    setApprovals(state, approvals) {
      state.approvals = approvals;
    },
    setOrderRemindersTypes(state, orderReminderTypes){
      state.orderReminderTypes = orderReminderTypes;
    },
    setClientProjectsStatus(state, projectStatuses) {
      state.projectStatuses = projectStatuses;
    },
    setTyreTypes(state, tyreTypes) {
      state.tyreTypes = tyreTypes;
    },
    setWheelTypes(state, wheelTypes) {
      state.wheelTypes = wheelTypes;
    },
    setWheelMaterials(state, wheelMaterials) {
      state.wheelMaterials = wheelMaterials;
    },
    setTyreSeasons(state, tyreSeasons) {
      state.tyreSeasons = tyreSeasons;
    },
    setClassCodes(state, classCodes) {
      state.classCodes[classCodes.key] = classCodes.values;
    },
    setStatuses(state, statuses) {
      state.statuses = statuses;
    },
    setFamilyClients(state, familyClients) {
      state.familyClients = familyClients;
    },
    setResourceTypes(state, resourceTypes) {
      state.resourceTypes = resourceTypes;
    },
    setRefreshList(state, refreshList) {
      state.refreshList = refreshList;
    },
    setAttachmentCounts(state, attachmentCounts) {
      state.attachmentCounts = attachmentCounts;
    },
    setRentalVehicleForm(state, value) {
      // console.log('kjgjkgjk', value)
      state.addRentalVehicleFormActive = value;
    },
    setRentalId(state, value) {
      state.rentalId = value;
    },
    setVehicles(state, value) {
      state.vehicles = value;
    },
    setCompanyLanguages(state, value) {
      state.companyLanguages = value;
    },
    setAppointmentStatuses(state, value) {
      state.appointmentStatuses = value;
    },
    setCountries(state, countries) {
      state.countries = countries;
    },
    setBankAccounts(state, bankAccounts) {
      state.bankAccounts = bankAccounts;
      let tempCurrencies = [];
      bankAccounts.forEach((element) => {
        tempCurrencies.push(element.currency);
        if(element.is_default){
          state.defaultBankAccount = element;
        }
      })
      state.currencies = tempCurrencies;
    },
    SHOW_EDIT_VEHICLE_MODAL(state){
      state.editVehicleModalKey++;
    },
    setNavigationData(state, navigationData){
        state.navigationData = navigationData;
    },
    setSelectedArticlePendingAppointment(state, selectedArticlePendingAppointment){
        state.selectedArticlePendingAppointment = selectedArticlePendingAppointment;
    },
    setDynamicLoadedComponents(state, dynamicLoadedComponents){
        state.dynamicLoadedComponents = dynamicLoadedComponents;
    },
    setCopiedAppointment(state, copiedAppointment){
        state.copiedAppointment = copiedAppointment;
    },
    setExportStatus(state, exportStatus){
        state.exportStatus = exportStatus;
    }
  },
  getters: {
    defaultCompanyLanguage(state) {
      return state.companyLanguages.find(
        (language) => language.is_default == 1
      );
    },
  },
  actions: {
    setProgressBarLoading({ commit, state }, progressBarLoading) {
        // Clear any existing timeout
        commit('clearLoadingTimeout');

        // Set the progressBarLoading state
        commit('setProgressBarLoading', progressBarLoading);

        if (progressBarLoading) {
            // Set a timeout to change progressBarLoading to false after 30 seconds
            const timeout = setTimeout(() => {
                commit('setProgressBarLoading', false);
                commit('clearLoadingTimeout');
            }, 30000);

            // Store the timeout ID in the state
            commit('setLoadingTimeout', timeout);
        }
    },  
    async fetchAddressData(state, search_query = null) {
      if (search_query && search_query != null) {
        const promise = await axios.get(
          "https://api.geoapify.com/v1/geocode/search?apiKey=" +
            this.mapApiKey +
            "&text=" +
            search_query.toString() +
            "&format=json"
        );

        Promise.all([promise]).then(function(values) {
          return values;
        });
      }
    },
    async fetchCompanyLanguages(context) {
      return new Promise((resolve, reject) => {
        if (context.state.companyLanguages.length > 0) {
          resolve(context.state.companyLanguages);
        }

        axios
          .get(API_BASE_URL + "/company_languages", {
            headers: {
              Authorization: "Bearer " + context.state.AccessToken,
            },
          })
          .then(({ data }) => {
            let response = data.data;
            context.commit("setCompanyLanguages", response);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    forceReloadComponent({ state }) {
      state.forceReloadComponentKey = Math.floor(Math.random() * 10000);
    },
    fetchAppointmentStatuses(context) {
      return new Promise((resolve, reject) => {
        if (context.state.appointmentStatuses.length > 0) {
          resolve(context.state.appointmentStatuses);
        }

        axios
          .get(API_BASE_URL + "/codes?class_key=appointment_statuses", {
            headers: {
              Authorization: "Bearer " + context.state.AccessToken,
            },
          })
          .then(({ data }) => {
            let response = data.data;
                context.commit("setAppointmentStatuses", response);
                resolve(response);
              })
          .catch((error) => reject(error));
      });
    },
  },
  modules: {},
});