<template>
  <v-row >
    <v-col>
    <v-row>
      <v-col class="pa-0 ma-0" cols="12" sm="9">
    <h1 align="center">Dynamic View Test for DEMO COMPANY</h1>

      </v-col>
      <v-col class="pa-0 ma-0" cols="12" sm="3">
        <v-text-field
          class="mr-3 to-upper"
          :label="$t('search')"
          prepend-icon="mdi-magnify"
          v-bind:clearable="true"
          clear-icon="mdi-close"
          v-model="search"
          :outlined="is_boxes"
          :dense="is_boxes"
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row>
      <v-data-table
        fixed-header
        :footer-props="{
          'items-per-page-options': dataTableOptions
        }"
        :headers="headers"
        :items="clients"
        :options="options"
        :server-items-length="totalItems"
        :loading-text="this.$t('loading')"
        class="elevation-0"
        :items-per-page="itemsPerPage"
        item-key="identifier"
        @update:page="updatePagination"
        @update:items-per-page="updateItemsPerPage"
        @click:row="editItem"
        :height="'calc(-200px + 100vh)'"
        style="width: 100%"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @update:sort-by="updateSort"
        @update:sort-desc="updateSortOrder"
        mustSort
      >
      <template v-slot:item="{ item }">
        <tr>
          <td @click="editItem(item)">{{ item.id }}</td>
          <td @click="editItem(item)">{{ item.name }}</td>
          <td @click="editItem(item)">
            <span v-if="item.company">
                {{ $t('clients.yes') }}
            </span>
            <span v-else>
                {{ $t('clients.no') }}
            </span>
          </td>
          <td @click="editItem(item)">
            <span v-if="item.company">{{ item.company.phone }}</span>
            <span v-else>{{ item.phone }}</span>
          </td>
          <td @click="editItem(item)">
            <span v-if="item.company">{{ item.company.gsm }}</span>
            <span v-else>{{ item.gsm }}</span>
          </td>
          <td @click="editItem(item)">
            <span v-if="item.company">{{ item.company.email }}</span>
            <span v-else>{{ item.email }}</span>
          </td>
          <td @click="editItem(item)">{{ item.currency.key }}</td>
          
          <!-- <td @click="editItem(item)">{{ item.user }}</td> -->
          <!-- <td>
            <v-icon
              color="success"
              v-if="item.status.id == 10041001" 
              class="mr-1"
            >
              mdi-check
            </v-icon>
            <v-icon
              v-if="item.status.id == 10041000" 
              class="mr-1"
            >
              mdi-account-alert
            </v-icon>
            <v-icon
              color="red"
              v-if="item.status.id == 10041002" 
              class="mr-1"
            >
              mdi-server-remove
            </v-icon>
          </td> -->
          <td @click="editItem(item)">{{ item.familyClient.name }}</td>
          <td @click="editItem(item)">
            <v-icon v-if="item.note" color="warning" class="mr-1">
              mdi-information
            </v-icon>
          </td>
        </tr>
      </template>
      </v-data-table>
    </v-row>
    </v-col>
  </v-row>
</template>

<script>
import axios from '@/plugins/axios';
import _ from 'lodash';
import { API_BASE_URL } from "@/config";
import { VIEW_MODE } from "@/config";
import { EDIT_MODE } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";


export default {
  name: "ClientsListing",
  data () {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
      isLoading: true,
      totalItems: 0,
      page: 1,
      lastPage: 1,
      sortBy:'name',
      sortDesc:true,
      sortOrd:'ASC',
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      clients: [],
      search: '',
      options: {},
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      // header
      token: this.$store.state.AccessToken,
      header: { Authorization: 'Bearer ' + this.$store.state.AccessToken },
    }
  },
  mounted () {
    this.header = { Authorization: 'Bearer ' + this.token };
    this.$store.commit('setFormMode', VIEW_MODE);
    this.$store.commit('setAddButtonRoute', 'AddClient')
    this.fetchData();
  },
  created(){
    this.$watch('search', _.debounce(() => {
      this.isLoading = true;
      this.fetchData()
    }, 500))
  },
  methods: {
    fetchData () {
      this.isLoading = true
      axios.get(API_BASE_URL + '/clients?searchRegNumber=true&page=' + this.page + '&items_per_page=' + this.itemsPerPage + (this.search ? ('&search=' + encodeURIComponent(this.search)) : '' + '&sortby=' + this.sortBy + '&sorttype=' + this.sortOrd), {
        headers: this.header
      })
        .then(({ data }) => {
          this.clients = data.data;
          this.lastPage = data.meta.last_page;
          this.totalItems = data.meta.total;
          this.page = data.meta.current_page;
          // this.itemsPerPage = parseInt(data.meta.per_page);
          // this.sortBy = null;
          // this.sortDesc = null;
          this.isLoading = false
        })
        .catch((err) => {
          console.log(err)
          this.isLoading = false
        });

      
    },
    updatePagination (page) {
      this.page = page;
      this.fetchData();
    },
    updateItemsPerPage (itemsPerPage) {
      this.itemsPerPage = (itemsPerPage == -1 ? this.totalItems : itemsPerPage)
      this.fetchData();
      this.itemsPerPage = (this.itemsPerPage == -1 ? -1 : itemsPerPage);
    },
    editItem (item) {
      this.$store.commit("seteditId",item.id);
      this.$store.commit('setFormMode', EDIT_MODE);
      this.$router.push('/client/edit').catch(() => {});
    },
    deleteItem (item) {
      console.log("Delete Item");
    },
    

    updateSort (sortBy) {
      if (typeof sortBy == 'object') {
        this.sortBy = sortBy[0]
      } else {
        this.sortBy = sortBy
      }
      this.fetchData()
    },
    updateSortOrder (sortDesc) {
      if (typeof sortDesc == 'object') {
        !sortDesc[0] ? this.sortOrd = 'ASC' : this.sortOrd = 'DESC'
      } else {
        !sortDesc ? this.sortOrd = 'ASC' : this.sortOrd = 'DESC'
      }
      
      this.fetchData()
    },
    onSearch () {
      this.fetchData();
    }
  },
  computed:{
    headers(){
      return [
        { text: 'ID', value: 'id'},
        { text: this.$t('clients.name'), align: 'start', sortable: true, value: 'name',},
        { text: this.$t('clients.company'), value: 'representative_company_id' },
        // { text:  this.$t('clientOtherName'), align: 'start', value: 'other_name' },
        { text: this.$t('clients.phone'), value: 'phone' },
        { text: this.$t('clients.gsm'), value: 'gsm' },
        // {text: this.$t('clientFax'), value: 'fax'},
        { text: this.$t('clients.email'), value: 'email' },
        // {text: this.$t('clients.web'), value: 'web'},
        { text: this.$t('clients.currency'), value: 'currency.key' , sortable:false},
        // { text: this.$t('clients.user'), value: 'user.name' , sortable:false},
        // { text: this.$t('clients.status'), value: 'status.key', sortable:false },
        { text: this.$t('clients.family'), value: 'family_client', sortable:false },
        { text: this.$t('clients.note'), value: 'note' },
        // {text: this.$t('createdAt'), value: 'created_at'},
        // {text: this.$t('updatedAt'), value: 'updated_at'},
        // { text: this.$t('actions'), value: 'actions', sortable: false, align: 'end' }
      ];
    }
  },
}

</script>

<style scoped>
</style>
