<template>
  <v-row>
    <v-col class="pb-0">
      <v-row>
        <v-col class="ma-0 pa-0" cols="12" sm="8"> </v-col>
        <v-col class="ma-0 pa-0" cols="12" sm="4">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            class="mr-3 to-upper"
            :label="$t('search')"
            prepend-icon="mdi-magnify"
            v-bind:clearable="true"
            clear-icon="mdi-close"
            v-model="search"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>
      </v-row>

      <div>
        <v-data-table
          :height="'calc(-210px + 100vh)'"
          fixed-header
          :footer-props="{
            'items-per-page-options': dataTableOptions,
          }"
          :headers="headers"
          :items="family_articles"
          :options="options"
          :server-items-length="totalItems"
          :loading-text="this.$t('loading')"
          class="elevation-0"
          :items-per-page="itemsPerPage"
          item-key="identifier"
          @update:page="updatePagination"
          @update:items-per-page="updateItemsPerPage"
          @click:row="editItem"
          style="width: 100%"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          @update:sort-by="updateSort"
          @update:sort-desc="updateSortOrder"
          mustSort
          :key="forceReloadComponentKey"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td @click="editItem(item)">{{ item.id || "-" }}</td>
              <td @click="editItem(item)">{{ item.name || "-" }}</td>
              <td @click="editItem(item)">
                {{ (item.tva ? item.tva.tax + "%" : "") || "-" }}
              </td>
              <td @click="editItem(item)">
                {{
                  (item.mu ? $t("measurement_units." + item.mu.key) : "") || "-"
                }}
              </td>
              <td @click="editItem(item)">
                {{ item.default_quantity || "0" }}
              </td>
              <td @click="editItem(item)">
                {{ item.quantity_decimals || "0" }}
              </td>
              <td @click="editItem(item)">
                <span v-if="item.is_stock_managed == 1">
                  {{ $t("yes") }}
                </span>
                <span v-else>
                  {{ $t("no") }}
                </span>
              </td>
              <td @click="editItem(item)">
                <span v-if="item.is_default == 1">
                  <v-icon color="success" class="mr-1">
                    mdi-check
                  </v-icon>
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { VIEW_MODE } from "@/config";
import { EDIT_MODE } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";

export default {
  name: "FamilyClient",
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      isLoading: true,
      totalItems: 0,
      page: 1,
      lastPage: 1,
      sortBy: "id",
      sortDesc: true,
      sortOrd: "DESC",
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      family_articles: [],
      search: null,
      options: {},
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      // header
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      autocompleteValueLocal: "off",
    };
  },
  mounted() {
    this.autocompleteValueLocal = this.autocompleteValue();
    (this.header = { Authorization: "Bearer " + this.token }),
      this.$store.commit("setFormMode", VIEW_MODE);
    this.$store.commit("setAddButtonRoute", "AddClient");
    this.fetchData();
    this.getFamilyArticles();
  },
  created() {},
  methods: {
    getFamilyArticles() {
      axios
        .get(API_BASE_URL + "/family_articles?items_per_page=-1", {
          headers: { Authorization: "Bearer " + this.$store.state.AccessToken },
        })
        .then(({ data }) => {
          this.$store.commit("setFamilyArticles", data.data);
        })
        .catch(function(error) {
          console.log("an error occured " + error);
        });
    },
    fetchData(currentPage = null) {
      if (!currentPage) {
        this.options.page = 1;
      }
      this.resetDataTable(currentPage);
      this.isLoading = true;
      axios
        .get(
          API_BASE_URL +
            "/family_articles?page=" +
            this.page +
            "&items_per_page=" +
            this.itemsPerPage +
            (this.search
              ? "&search=" + encodeURIComponent(this.search)
              : "" + "&sortby=" + this.sortBy + "&sorttype=" + this.sortOrd),
          { headers: this.header }
        )
        .then(({ data }) => {
          this.family_articles = data.data;
          this.lastPage = data.meta.last_page;
          this.totalItems = data.meta.total;
          this.page = data.meta.current_page;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    updatePagination(page) {
      this.page = page;
      this.fetchData(page);
    },
    updateItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage == -1 ? this.totalItems : itemsPerPage;
      this.fetchData();
      this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
    },
    editItem(item) {
      this.$store.commit("seteditId", item.id);
      this.$store.commit("setFormMode", EDIT_MODE);
      this.$router.push("/family-articles/edit").catch(() => {});
    },
    deleteItem(item) {
      console.log("Delete Item");
    },

    updateSort(sortBy) {
      console.log("data =>", sortBy);
      if (typeof sortBy == "object") {
        this.sortBy = sortBy[0];
      } else {
        this.sortBy = sortBy;
      }
      this.fetchData();
      console.log("sort by => ", this.sortBy);
    },
    updateSortOrder(sortDesc) {
      console.log("data => ", sortDesc);
      if (typeof sortDesc == "object") {
        !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      } else {
        !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      }

      this.fetchData();
      console.log("sort order => ", this.sortOrd);
    },
    onSearch() {
      this.fetchData();
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("family_article.id"),
          align: "start",
          sortable: false,
          value: "id",
          width: "100",
        },
        {
          text: this.$t("family_article.name"),
          align: "start",
          sortable: false,
          value: "name",
          width: "300",
        },
        {
          text: this.$t("family_article.tva"),
          align: "start",
          sortable: false,
          value: "tva_id",
          width: "100",
        },
        {
          text: this.$t("family_article.mu"),
          align: "start",
          sortable: false,
          value: "mu",
          width: "100",
        },
        {
          text: this.$t("family_article.default_quantity"),
          align: "start",
          sortable: false,
          value: "default_quantity",
          width: "150",
        },
        {
          text: this.$t("family_article.quantity_decimals"),
          align: "start",
          sortable: false,
          value: "quantity_decimals",
          width: "150",
        },
        {
          text: this.$t("family_article.is_stocks_managed"),
          align: "start",
          sortable: false,
          value: "is_stock_managed",
          width: "200",
        },
        {
          text: this.$t("family_article.is_default"),
          align: "start",
          sortable: false,
          value: "is_default",
          width: "100",
        },
      ];
    },
  },
  watch: {
    search: {
      handler: function() {
        this.fetchData();
      },
    },
  },
};
</script>

<style scoped></style>
