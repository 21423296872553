<template>
  <div>
    <v-row>
      <v-col cols="3">
        <v-text-field
            class="mr-3 to-upper"
            :label="$t('search')"
            prepend-icon="mdi-magnify"
            v-bind:clearable="true"
            clear-icon="mdi-close"
            v-model="search"
            :loading="searching"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="formattedEmployees"
      @click:row="editEmployee"
      @update:page="updatePagination"
      @update:items-per-page="updateItemsPerPage"
      fixed-header
      :footer-props="{
            'items-per-page-options': [...perPageOptions],

          }"
      :server-items-length="total"
      class="elevation-0"
      :height="'calc(-200px + 100vh)'"
      >
  </v-data-table>
    <employee-form
        v-model="showForm"
        :employee="selectedEmployee"
        @created="(employee) => createdHandler(employee)"
        @updated="(employee) => updatedHandler(employee)"
        @deleted="(employee) => deletedHandler(employee)"
    />
    <span @click="openForm()" class="d-none" id="add-new"></span>
  </div>
</template>
<script>
import clockingMixin from "@/mixins/clockingMixin";

export default {
  name: "EmployeesListing",
  mixins: [clockingMixin],
  components: {
  EmployeeForm: () => import('@/views/Clocking/Employees/Form.vue')
    },
  data() {
    return {
      axiosOptions: {Authorization: "Bearer " + this.$store.state.AccessToken},
      selectedEmployee: null,
      showForm: false,
      search: null,
      headers: [
        {
          text: this.$t('srno'),
          width: 50,
          value: 'serial',
          sortable: false
        },
        {
          text: this.translate('name'),
          value: 'name',
          sortable: false,
          align: 'left'
        },
        {
          text: this.translate('national_id'),
          value: 'national_id',
          sortable: false
        },
        {
          text: this.translate('badge'),
          value: 'badge',
          sortable: false
        },
      ]
    };
  },
  computed:{
    formattedEmployees() {
      return this.employees.map((item) => {
        item.name = item.last_name + " " + item.first_name;
        return item;
      })
    }
  },
  async created() {
    this.employees = await this.getEmployees();
  },
  watch:{
    showForm(val) {
      if(!val) {
        this.selectedEmployee = null;
      }
    },
    search(val) {
      this.searchEmployees(val);
    }
  },
  methods: {
    openForm(employee = null) {
      this.showForm = false;

      setTimeout(() => {
        if(employee) this.selectedEmployee = employee;
        this.showForm = true
      }, 10);
    },
    createdHandler(employee) {
      if(this.employees.length === 0){
        this.employees = [employee];
        return;
      }
      this.employees.unshift(employee);
    },
    updatedHandler(employee) {
      const index = this.employees.findIndex((item) => item.id === employee.id);
      this.employees.splice(index, 1, employee);
    },
    deletedHandler(employee) {
      this.employees = this.employees.filter((item) => item.id !== employee.id);
    },
    editEmployee(employee) {
      this.selectedEmployee = employee;
      this.openForm(employee)
    },
    async updatePagination(page) {
      this.page = page;
      this.employees = await this.getEmployees();
    },
    async updateItemsPerPage(itemsPerPage) {
      this.perPage = itemsPerPage
      this.employees = await this.getEmployees();
    },
    async searchEmployees(search) {
      // cancel pending call
      clearTimeout(this.timerId)

      // delay new call 500ms
      this.timerId = setTimeout(async() => {
        this.searching = true;
        this.employees = await this.getEmployees(search)
        this.searching = false;
      }, 500)
    }
  },
};
</script>