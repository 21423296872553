<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="formattedContracts"
      @click:row="editRow"
      class="elevation-0"
      :height="'calc(-150px + 100vh)'"
    >
  <template v-slot:[`item.is_working_in_shifts`]="{ item }">
    <v-btn icon color="success" small v-if="item.is_working_in_shifts">
      <v-icon>mdi-check</v-icon>
    </v-btn>
    <v-btn icon color="error" small v-else>
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </template>
    </v-data-table>
    <EmploymentContractForm
        v-model="showForm"
        @created="(data) => handleCreated(data)"
        @updated="(data) => handleUpdated(data)"
        @deleted="(data) => handleDeleted(data)"
        :employment-contract="selectedEmploymentContract" />
    <span @click="showEmploymentContractForm" class="d-none" id="add-new"></span>
  </div>
</template>
<script>
import clockingMixin from "@/mixins/clockingMixin";
import moment from "moment";

export default {
  name: "EmploymentContractListing",
  mixins: [clockingMixin],
  components: {
    EmploymentContractForm: () => import('@/views/Clocking/EmploymentContracts/Form.vue')
  },
  data(){
    return {
  headers: [
    {
      text: this.$t('srno'),
      width: 50,
      value: 'serial',
      sortable: false
    },
    {
      text: this.translate('name'),
      value: 'name',
      sortable: false
    },
    {
      text: this.translate('type'),
      value: 'clocking_type',
      sortable: false
    },
    {
      text: this.translate('working_hours_per_day'),
      value: 'work_hours_per_day',
      sortable: false
    },
    {
      text: this.translate('break_time_period'),
      value: 'break_time_period_label',
      sortable: false
    },
    {
      text: this.translate('break_time_start'),
      value: 'break_time_start',
      sortable: false
    },
    {
      text: this.translate('work_days_period'),
      value: 'work_days_period_label',
    },
    {
      text: this.translate('vacation_days_period'),
      value: 'vacation_days_period_label',
    },
    {
      text: this.translate('start_time'),
      value: 'work_time_start',
    },
    {
      text: this.translate('end_time'),
      value: 'work_time_end'
    },
    {
      text: this.translate('is_shift'),
      value: 'is_working_in_shifts'
    }
  ],
      measurementUnits: [],
      showForm: false,
      selectedEmploymentContract: null,
      showClockingForDayModal: false,
    }
  },
  async mounted(){
    this.clockingTypes = await this.getClockingTypes();
    this.employmentContractTypes = await this.getCodes('employment_contract_types', false)
    this.measurementUnits = await this.getCodes('measurement_units', false)
    this.employmentContracts = await this.getEmploymentContracts();
  },
  watch:{
    showForm(val){
      if(!val){
        this.selectedEmploymentContract = null;
      }
    }
  },
  computed: {
    formattedContracts(){
      return this.employmentContracts.map( (item, index) => {
        item.serial = index + 1;

        let type = this.employmentContractTypes.find( type => type.id === item.type_id);
        item.clocking_type = this.translate('types.' + type.key)

        let measurementUnit = this.measurementUnits.find( type => type.id === item.break_time_period)
        item.break_time_period_label = item.break_time_per_day + ' ' + this.$t('measurement_units.' + measurementUnit.key)

        let workDaysPeriod = this.measurementUnits.find( type => type.id === item.work_days_period)
        item.work_days_period_label = item.work_days + ' ' + this.$t('measurement_units.' + workDaysPeriod.key)

        let vacationDaysPeriod = this.measurementUnits.find( type => type.id === item.vacation_days_period)
        item.vacation_days_period_label = item.vacation_days + ' ' + this.$t('measurement_units.' + vacationDaysPeriod.key) + '(s)'

        item.break_time_start = this.formatTimeWithLocale(item.default_start_break_time)

        item.work_time_start = this.formatTimeWithLocale(item.default_start_time)
        item.work_time_end = this.formatTimeWithLocale(item.default_end_time)

        return item
      })
    }
  },
  methods: {

    showEmploymentContractForm(){
      this.showForm = false;
      setTimeout(() => this.showForm = true, 10)
    },
    handleCreated(employmentContract){
      this.employmentContracts.push(employmentContract)
    },
    handleUpdated(employmentContract){
      let index = this.employmentContracts.findIndex( item => item.id === employmentContract.id)
      this.employmentContracts.splice(index, 1, employmentContract)
    },
    handleDeleted(employmentContract){
      let index = this.employmentContracts.findIndex( item => item.id === employmentContract.id)
      this.employmentContracts.splice(index, 1)
    },
    editRow(item){
      this.selectedEmploymentContract = item;
      this.showEmploymentContractForm();
    }
  }
}
</script>
