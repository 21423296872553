var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-form',{ref:"form",staticClass:"main_form",attrs:{"id":"client-form"},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-btn',{staticClass:"d-none",attrs:{"type":"submit","id":"submit-client"}}),_c('v-row',{staticClass:"mt-12"},[_c('v-row',{staticClass:"ma-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"2"}},[_c('v-row',[_c('v-col',{attrs:{"lg":"12","cols":"auto"}},[_c('label',{staticClass:"logoImage ma-4"},[_c('img',{staticStyle:{"width":"150px","height":"150px","border-radius":"50%","border":"3px dotted #efefef"},attrs:{"src":_vm.logoImage}}),_c('input',{ref:"files",staticClass:"d-none",attrs:{"type":"file","id":"files","multiple":""},on:{"change":function($event){return _vm.handleFilesUpload()}}})])]),_c('v-col',[_c('v-card',{attrs:{"flat":""}},[(_vm.companyDetails)?_c('v-card-subtitle',[_c('strong',[_vm._v(" "+_vm._s(_vm.companyDetails.name)+" ")]),_vm._v(" "),_c('br'),_c('strong',[_vm._v(" "+_vm._s(_vm.$t("company_settings.vat"))+" : ")]),_vm._v(" "+_vm._s(_vm.vat)+" ")]):_vm._e()],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"10"}},[_c('v-row',[_c('v-text-field',{staticClass:"to-upper mr-2",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('company_settings.name'),"error-messages":_vm.$store.state.validation && !_vm.$v.name.required
                      ? [this.$t('company_settings.text_messages.required_name')]
                      : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){return _vm.handleInput($event, 'name', 60)}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-select',{staticClass:"to-upper mr-2",attrs:{"items":_vm.denominations,"item-value":"id","item-text":"text","label":_vm.$t('billings.denomination'),"error-messages":_vm.$store.state.validation && !_vm.$v.denomination.required
                        ? [this.$t('billings.text_messages.denomination_required')]
                        : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();},"change":function($event){return _vm.$emit('update:comp_denomination', _vm.denomination)}},model:{value:(_vm.denomination),callback:function ($$v) {_vm.denomination=$$v},expression:"denomination"}}),_c('v-text-field',{staticClass:"to-upper mr-2",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('company_settings.registrationNumber'),"error-messages":_vm.$store.state.validation && !_vm.$v.registrationNumber.required
                      ? [
                          this.$t(
                            'company_settings.text_messages.required_registrationNumber'
                          ) ]
                      : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){return _vm.handleInput($event, 'registrationNumber', 60)}},model:{value:(_vm.registrationNumber),callback:function ($$v) {_vm.registrationNumber=$$v},expression:"registrationNumber"}}),_c('v-text-field',{staticClass:"to-upper mr-2",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('company_settings.vat'),"error-messages":_vm.$store.state.validation && !_vm.$v.vat.required
                      ? [this.$t('company_settings.text_messages.required_vat')]
                      : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){return _vm.handleInput($event, 'name', 60)}},model:{value:(_vm.vat),callback:function ($$v) {_vm.vat=$$v},expression:"vat"}})],1),_c('v-row',[_c('v-text-field',{staticClass:"to-upper mr-2",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('company_settings.email'),"error-messages":// $store.state.validation && !$v.email.email
                    //   ? [this.$t('company_settings.text_messages.required_email')]
                    //   : []
                    [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){return _vm.handleInput($event, 'email', 60)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('vue-tel-input-vuetify',{attrs:{"disabledFetchingCountry":true,"preferredCountries":_vm.preferredCountry,"filterCountries":_vm.onlyCountries,"validCharactersOnly":"","label":_vm.$t('company_settings.phone'),"inputOptions":{ showDialCode: true, tabindex: 0 },"mode":'international'},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('vue-tel-input-vuetify',{attrs:{"disabledFetchingCountry":true,"preferredCountries":_vm.preferredCountry,"filterCountries":_vm.onlyCountries,"validCharactersOnly":"","label":_vm.$t('company_settings.fax'),"inputOptions":{ showDialCode: true, tabindex: 0 },"mode":'international'},model:{value:(_vm.fax),callback:function ($$v) {_vm.fax=$$v},expression:"fax"}}),_c('vue-tel-input-vuetify',{attrs:{"disabledFetchingCountry":true,"preferredCountries":_vm.preferredCountry,"filterCountries":_vm.onlyCountries,"validCharactersOnly":"","label":_vm.$t('company_settings.gsm'),"inputOptions":{ showDialCode: true, tabindex: 0 },"mode":'international'},model:{value:(_vm.gsm),callback:function ($$v) {_vm.gsm=$$v},expression:"gsm"}}),_c('v-text-field',{staticClass:"to-upper mr-2",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('company_settings.web'),"error-messages":[],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){return _vm.handleInput($event, 'web', 60)}},model:{value:(_vm.web),callback:function ($$v) {_vm.web=$$v},expression:"web"}})],1),(_vm.is_peppol)?_c('v-row',[_c('v-text-field',{staticClass:"to-upper mr-2",attrs:{"label":_vm.$t('company_settings.end_point_id'),"placeholder":_vm.$t('company_settings.end_point_id'),"required":"","error-messages":_vm.$store.state.validation && !_vm.$v.peppol_endpoint_id.required
                      ? [
                          this.$t(
                            'company_settings.text_messages.peppol_endpoint_id_required'
                          ) ]
                      : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();},function($event){return _vm.$v.$touch()}]},model:{value:(_vm.peppol_endpoint_id),callback:function ($$v) {_vm.peppol_endpoint_id=$$v},expression:"peppol_endpoint_id"}}),_c('v-text-field',{staticClass:"to-upper mr-2",attrs:{"label":_vm.$t('company_settings.scheme_id'),"placeholder":_vm.$t('company_settings.scheme_id'),"required":"","error-messages":_vm.$store.state.validation && !_vm.$v.peppol_scheme_id.required
                      ? [
                          this.$t(
                            'company_settings.text_messages.peppol_scheme_id_required'
                          ) ]
                      : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();},function($event){return _vm.$v.$touch()}]},model:{value:(_vm.peppol_scheme_id),callback:function ($$v) {_vm.peppol_scheme_id=$$v},expression:"peppol_scheme_id"}})],1):_vm._e(),_c('tabs',{staticClass:"mt-8",attrs:{"prop_address":_vm.address,"prop_shipping_address":_vm.shipping_address,"prop_workpointdata":_vm.working_points,"prop_company_properties":_vm.company_properties},on:{"update:prop_address":function($event){_vm.address=$event},"update:prop_shipping_address":function($event){_vm.shipping_address=$event},"update:prop_workpointdata":function($event){_vm.working_points=$event},"update:prop_company_properties":function($event){_vm.company_properties=$event}}})],1)],1)],1)],1)],1),_c('v-btn',{staticClass:"d-none",attrs:{"id":"submit-company-setting"},on:{"click":function($event){return _vm.onSubmit()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }