<template>
  <v-dialog persistent  v-model="showLoadingModel" min-width="400" max-width="500">
      <v-card>
          <v-card-text class="pt-2">
          {{ $t('loading_please_wait') }}
          <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
  </v-dialog>
</template>

<script>
export default {
    name:       "LoadingModel",
    props: {
      showLoadingModel: [Number, Boolean],
    },
    data() {
        return {
          
        }
    },
}
</script>
