var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-8"},[_c('v-row',_vm._l((_vm.getPropertiesForSection(
                _vm.prop_company_properties,
                'others'
            )),function(property,index){return _c('v-col',{key:index,staticClass:"ma-0 pa-0 pl-4 pb-3",attrs:{"cols":"4"}},[(
                    !_vm.checkIfPropertyIsOtherThenText(property) &&
                        !_vm.checkIfPropertyIsColor(property) &&
                        !_vm.checkIfPropertyIsDefaultBillingDocument(property)
                )?_c('v-text-field',{attrs:{"type":_vm.isPasswordField(property) ? 'password' : 'text',"label":_vm.getTranslation(property.company_setting_key)},model:{value:(property.value),callback:function ($$v) {_vm.$set(property, "value", $$v)},expression:"property.value"}}):_vm._e(),(_vm.checkIfPropertyIsColor(property))?_c('v-menu',{attrs:{"nudge-bottom":"105","nudge-left":"16","close-on-content-click":false,"hide-details":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-row',{staticClass:"d-flex"},[_c('v-col',{staticClass:"mb-0 pb-0 extra_label",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.getTranslation(property.company_setting_key))+" ")]),_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"12"}},[_c('div',_vm._g({style:(_vm.swatchStyle(property))},on))])],1)]}}],null,true),model:{value:(property.menu),callback:function ($$v) {_vm.$set(property, "menu", $$v)},expression:"property.menu"}},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-color-picker',{attrs:{"mode":"hexa","flat":""},model:{value:(property.value),callback:function ($$v) {_vm.$set(property, "value", $$v)},expression:"property.value"}})],1)],1)],1):_vm._e(),(_vm.billTypes && _vm.billTypes.length > 0 && _vm.checkIfPropertyIsDefaultBillingDocument(property))?_c('v-autocomplete',{attrs:{"items":_vm.billTypes,"label":_vm.getTranslation(property.company_setting_key),"return-object":false,"item-value":"id","item-text":"text"},model:{value:(property.value),callback:function ($$v) {_vm.$set(property, "value", $$v)},expression:"property.value"}}):_vm._e()],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }