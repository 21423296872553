<template>
  <pricing-calculator></pricing-calculator>
</template>

<script>
import PricingCalculator from '@/components/ArticlePricing/PricingCalculator.vue'
export default {
  components: { PricingCalculator },

}
</script>

<style>

</style>