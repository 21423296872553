<template>
    <div class="home">
        <h1 align="center">Dynamic View Test for DEMO COMPANY</h1>
      <Dashboard />
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Dashboard from '@/components/Dashboard';
  import { VIEW_MODE } from "@/config";
  
  export default {
    name: 'Home',
    components: {
      Dashboard
    },
    computed: {
    },
    methods: {
    },
    mounted(){
      this.$store.commit('setFormMode', VIEW_MODE);
    }
  }
  </script>
  