<template>
  <v-row>
    <v-col cols="12" class="pb-0 mb-0">
      <span style="color: #0A47A7">{{ translate('cron') }}</span>
    </v-col>
    <v-col >
      <v-text-field  v-model="formData.min" :label="translate('minute')" ></v-text-field>
    </v-col>
    <v-col >
      <v-text-field v-model="formData.hour" :label="translate('hour')"></v-text-field>
    </v-col>
    <v-col >
      <v-text-field v-model="formData.day" :label="translate('day')"></v-text-field>
    </v-col>
    <v-col >
      <v-text-field v-model="formData.month" :label="translate('month')"></v-text-field>
    </v-col>
    <v-col >
      <v-text-field v-model="formData.dow" :label="translate('day_of_week')"></v-text-field>
    </v-col>
    <v-col>
      <v-checkbox
        :label="translate('enabled')"
        v-model="formData.is_enabled"
        />
    </v-col>
  </v-row>
</template>
<script>
export default{
  name: "CronForm",
  emits: ['input'],
  props: {
    value: {
      default: {},
    }
  },
  data(){
    return {
      formData: {}
    }
  },
  mounted() {
    this.formData = this.value;
  },
  watch: {
    value(val){
      this.formData = val;
    },
    formData: {
      handler(val){
        this.$emit('input', val);
      },
      deep: true
    }
  },
  methods: {
    translate(translationKey, args = []) {
      return this.$t('supplier_connections_section.' + translationKey, args);
    },
  }
}
</script>