<template>
  <v-row>
    <v-data-table
      fixed-header
      :footer-props="{
        'items-per-page-options': dataTableOptions,
      }"
      :headers="headers"
      :items="pricingRules"
      :items-per-page="itemsPerPage"
      :options.sync="options"
      :sort-by="sortBy"
      :server-items-length="totalItems"
      :loading-text="this.$t('loading')"
      @update:page="updatePagination"
      @update:items-per-page="updateItemsPerPage"
      class="elevation-0"
      :height="'calc(-208px + 100vh)'"
      item-key="identifier"
      style="width: 100%"
      @click:row="editItem"
      @update:sort-by="updateSort"
      @update:sort-desc="updateSortOrder"
      :key="forceReloadComponentKey"
    >
      <template v-slot:top>
        <v-toolbar flat class="pt-4">
          <v-col class="d-flex">
            <v-autocomplete
              flat
              ref="ruleType"
              :items="ruleTypes"
              v-model="ruleType"
              :label="$t('pricing_rule.rule_type')"
              class="mr-2 to-upper"
              item-value="id"
              item-text="text"
              return-object
              :append-icon="ruleType ? 'mdi-close' : ''"
              @click:append="ruleType = null"
              :outlined="is_boxes"
              :dense="is_boxes"
              hide-no-data
            ></v-autocomplete>
            <v-autocomplete
              flat
              ref="brand"
              :items="conditions"
              v-model="condition"
              :label="$t('pricing_rule.condition')"
              class="mr-2 to-upper"
              item-value="id"
              item-text="text"
              return-object
              :append-icon="condition ? 'mdi-close' : ''"
              @click:append="condition = null"
              :outlined="is_boxes"
              :dense="is_boxes"
              hide-no-data
            ></v-autocomplete>
            <v-autocomplete
              v-model="familyArticle"
              :items="familyArticles"
              :label="$t('pricing_rule.family_article')"
              item-value="id"
              item-text="name"
              class="mr-2 to-upper"
              return-object
              :append-icon="familyArticle ? 'mdi-close' : ''"
              @click:append="familyArticle = null"
              :outlined="is_boxes"
              :dense="is_boxes"
              hide-no-data
            ></v-autocomplete>
            <v-autocomplete
              v-model="supplier"
              :items="suppliers"
              :label="$t('pricing_rule.supplier')"
              item-value="id"
              item-text="name"
              class="mr-2 to-upper"
              return-object
              :append-icon="supplier ? 'mdi-close' : ''"
              @click:append="supplier = null"
              :outlined="is_boxes"
              :dense="is_boxes"
              hide-no-data
            ></v-autocomplete>
            <v-autocomplete
              v-model="familyClient"
              :items="familyClients"
              :label="$t('pricing_rule.family_client')"
              item-value="id"
              item-text="name"
              class="mr-2 to-upper"
              return-object
              :append-icon="familyClient ? 'mdi-close' : ''"
              @click:append="familyClient = null"
              :outlined="is_boxes"
              :dense="is_boxes"
              hide-no-data
            ></v-autocomplete>
            <!-- Start Date -->
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="to-upper"
                  v-model="startDate"
                  :label="$t('pricing_rule.start_date')"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  @blur="date = parseDate(startDate)"
                  v-on="on"
                  :append-icon="startDate ? 'mdi-close' : ''"
                  @click:append="startDate = null"
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-text-field>
              </template>
              <v-date-picker
                :first-day-of-week="1"
                :weekday-format="getDay"
                v-model="startDate"
                no-title
                @input="menu1 = false"
                :locale="userLanguageId"
              ></v-date-picker>
            </v-menu>
            <!-- End Date -->
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="to-upper"
                  v-model="endDate"
                  :label="$t('pricing_rule.end_date')"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  @blur="date = parseDate(endDate)"
                  v-on="on"
                  :append-icon="endDate ? 'mdi-close' : ''"
                  @click:append="endDate = null"
                  :outlined="is_boxes"
                  :dense="is_boxes"
                ></v-text-field>
              </template>
              <v-date-picker
                :first-day-of-week="1"
                :weekday-format="getDay"
                v-model="endDate"
                no-title
                @input="menu2 = false"
                :locale="userLanguageId"
              ></v-date-picker>
            </v-menu>

            <v-text-field
              class="ml-2 to-upper"
              :label="$t('receptions.reference')"
              v-bind:clearable="true"
              clear-icon="mdi-close"
              v-model="search"
              @focus="search = null"
              @input="debounceInput"
              :outlined="is_boxes"
              :dense="is_boxes"
            ></v-text-field>

            <v-tooltip bottom z-index="9999">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :disabled="!checkIfAnyFilterAdded()"
                  class="mb-4 ml-4 mr-4"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="clearFilters"
                >
                  mdi-backspace-outline
                </v-icon>
              </template>
              <span>{{ $t("pricing_rule.clear_filters") }}</span>
            </v-tooltip>

            <v-btn
              class="ml-2 text-white-color"
              :disabled="!checkIfAnyFilterAdded()"
              @click="show_bulk_updater = true"
              color="red"
              red
              ><v-icon class="mr-1">mdi-layers-edit</v-icon>
              {{ $t("pricing_rule.selection_edit") }}</v-btn
            >
            <bulk-updater
              :ruleType="ruleType"
              :condition="condition"
              :familyArticle="familyArticle"
              :supplier="supplier"
              :familyClient="familyClient"
              :startDate="startDate"
              :endDate="endDate"
              :search="search"
              v-if="show_bulk_updater == true"
              @modifyClose="bulk_updater_dialog_close"
            ></bulk-updater>
          </v-col>
        </v-toolbar>
      </template>
      <template v-slot:item="{ item }">
        <tr
          :class="setRowColorClass(item)"
          @click="editItem(item)"
          :key="item.id"
        >
          <td>
            <span v-if="item.id">
              {{ item.id }}
            </span>
          </td>
          <td>
            <span v-if="item.ruleType != null">
              {{ $t("pricing_rule.rule_types." + item.ruleType.key) }}
            </span>
          </td>
          <td>
            <span v-if="item.condition != null">
              {{ $t("pricing_rule.conditions." + item.condition.key) }}
            </span>
          </td>
          <td>
            <span v-if="item.familyArticle != null">
              {{ item.familyArticle.name }}
            </span>
          </td>
          <td>
            <span v-if="item.name != null">
              {{ item.name }}
            </span>
          </td>
          <td>
            <span v-if="item.supplier != null">
              {{ item.supplier.name }}
            </span>
          </td>
          <td>
            <span v-if="item.familyClient != null">
              {{ item.familyClient.name }}
            </span>
          </td>
          <td>
            <v-tooltip right z-index="9999">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="warning" v-bind="attrs" v-on="on">
                  mdi-information
                </v-icon>
              </template>
              <template>
                {{ concatProperties(item) }}
              </template>
            </v-tooltip>
          </td>
          <td>
            <span v-if="item.amount && item.amount > 0">
              +
            </span>
            {{ (item.amount ? item.amount : 0).toFixed(getDecimalNumber()) }}
          </td>
          <td>
            {{ (item.percent ? item.percent : 0).toFixed(getDecimalNumber()) }}%
          </td>
          <td>
            {{ item.start_date ? item.start_date : "" }}
          </td>
          <td>
            {{ item.end_date ? item.end_date : "" }}
          </td>

          <td>
            <span v-if="item.is_active">
              <v-icon color="success" class="mr-1">
                mdi-check
              </v-icon>
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-row>
</template>

<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { VIEW_MODE } from "@/config";
import { EDIT_MODE } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import { mdiBackspaceOutline } from "@mdi/js";
import BulkUpdater from "@/components/PricingRule/BulkUpdater.vue";

export default {
  name: "PricingRules",
  components: {
    BulkUpdater,
  },
  mounted() {
    (this.header = { Authorization: "Bearer " + this.token }),
      this.$store.commit("setFormMode", VIEW_MODE);
    this.$store.commit("setAddButtonRoute", "AddArticle", "EditArticle");
    // this.getAllCodes();
    this.getFamilyArticles();
    this.getConditions();
    this.getRuleTypes();
    this.getSuppliers();
    if (this.loadingFirstTime != 0) {
      this.applyPreviousFilters();
      this.isFirstTimeLoaded++;
    } else {
      this.fetchData();
    }
  },

  data(vm) {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      endDate: null,
      startDate: null,
      menu1: false,
      menu2: false,
      dialog: false,
      isFirstTimeLoaded: 0,
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      isLoading: false,
      options: {},
      lastPage: null,
      totalItems: null,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      sortBy: "id",
      sortDesc: false,
      pagination: {},
      conditions: [],
      condition: null,
      globalfamilyArticles: [],
      familyArticle: null,
      token: this.$store.state.AccessToken,
      header: {
        Authorization: "Bearer " + this.$store.state.AccessToken,
      },
      pricingRules: [],
      suppliers: [],
      supplier: null,
      familyClient: null,
      ruleTypes: [],
      ruleType: null,
      search: null,
      watchChanges: true,
      concatenateProperties: {
        "1": [
          "property_01",
          "property_02",
          "property_03",
          "property_04",
          "property_05",
          "property_06",
          "property_07",
          "property_08",
          "property_09",
          "property_10",
          "property_13",
          "property_14",
          "property_15",
          "property_16",
          "property_17",
          "property_18",
          "property_19",
          "property_20",
          "property_21",
          "property_22",
          "property_23",
          "property_24",
          "property_25",
          "property_26",
          "property_27",
          "property_28",
          "property_29",
          "property_30",
          "property_31",
          "property_32",
          "property_33",
          "property_34",
          "property_35",
          "property_36",
          "property_37",
          "property_38",
          "property_39",
          "property_40",
          "property_41",
          "property_42",
          "property_43",
          "property_44",
          "property_45",
          "property_46",
          "property_47",
          "property_48",
          "property_49",
          "property_50",
          "property_51",
          "property_52",
          "property_53",
          "property_54",
          "property_55",
          "property_56",
          "property_57",
          "property_58",
          "property_59",
          "property_60",
        ],
        "2": [
          "property_02",
          "property_04",
          "property_05",
          "property_06",
          "property_07",
          "property_08",
          "property_13",
          "property_14",
          "property_15",
          "property_16",
          "property_17",
          "property_18",
          "property_19",
          "property_20",
          "property_21",
          "property_22",
          "property_23",
          "property_24",
          "property_25",
          "property_26",
          "property_27",
          "property_28",
          "property_29",
          "property_30",
          "property_31",
          "property_32",
          "property_33",
          "property_34",
          "property_35",
          "property_36",
          "property_37",
          "property_38",
          "property_39",
          "property_40",
          "property_41",
          "property_42",
          "property_43",
          "property_44",
          "property_45",
          "property_46",
          "property_47",
          "property_48",
          "property_49",
          "property_50",
          "property_51",
          "property_52",
          "property_53",
          "property_54",
          "property_55",
          "property_56",
          "property_57",
          "property_58",
          "property_59",
          "property_60",
        ],
        "0": ["property_05"],
      },
      propertyNames: [],
      isDebounceCancelled: false,
      show_bulk_updater: false,
    };
  },
  methods: {
    convertToKeys(data) {
      if (data.length == 8) {
        let found = null;
        this.allCodes.forEach((element) => {
          if (element.id == data) {
            found = element;
          }
        });
        console.log("data", found);
        return found ? found.text : "";
      }
      return data;
    },
    setRowColorClass(item) {
      let className = "";
      if (!item.is_active) {
        className = "greyRow";
      }
      return className;
    },
    bulk_updater_dialog_close(e) {
      if (e) {
        this.fetchData();
      }
      this.show_bulk_updater = false;
    },
    checkIfAnyFilterAdded() {
      if (
        this.familyClient ||
        this.supplier ||
        this.familyArticle ||
        this.condition ||
        this.ruleType ||
        this.startDate ||
        this.endDate ||
        this.search
      ) {
        return true;
      }
      return false;
    },
    clearFilters() {
      this.watchChanges = false;
      this.ruleType = null;
      this.condition = null;
      this.familyArticle = null;
      this.supplier = null;
      this.familyClient = null;
      this.startDate = null;
      this.endDate = null;
      this.search = null;
      this.fetchData();
    },
    concatProperties(item) {
      var properties = "";
      let propertiesArray = [];
      for (const [key, value] of Object.entries(this.concatenateProperties)) {
        if (
          item.familyArticle.id == key ||
          item.familyArticle.parent_family_id == key
        ) {
          propertiesArray = value;
        }
      }
      if (item.brand_id) {
        properties +=
          this.$t("pricing_rule.brand") + ": " + item.brand.name + ", ";
      }

      for (const key in item) {
        if (item[key] != null && propertiesArray.includes(key)) {
          let underscorePos = key.search("_") + 1;
          let propertyNo = Number(key.substr(underscorePos));
          if (propertyNo >= 13) {
            // if property no is greater then 13 then it means we have boolean properties
            if (item[key] == "1" || item[key] == "true") {
              properties +=
                (this.propertyNames[item.family_article_id]
                  ? this.propertyNames[item.family_article_id][key]
                  : "") + ", ";
            }
          } else {
            properties +=
              (this.propertyNames[item.family_article_id]
                ? this.propertyNames[item.family_article_id][key]
                : "") +
              " : " +
              this.convertToKeys(item[key]) +
              ", ";
          }
        }
      }
      return properties.substr(0, properties.length - 2);
      // return item;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    debounceInput: _.debounce(function(e) {
      if (this.isDebounceCancelled) {
        this.isDebounceCancelled = false;
        return false;
      }
      this.fetchData();
    }, 500),
    fetchData(currentPage) {
      if (!currentPage) {
        this.options.page = 1;
        this.resetDataTable(currentPage);
      }
      this.pricingRules = []; // remove the data from the table
      this.isLoading = true;
      const { sortBy, sortDesc, itemsPerPage, page } = this.options;
      var filters = {
        page: this.page,
        items_per_page: this.itemsPerPage,
        order_by: this.sortBy,
        order_direction: this.sortOrd,
        condition: this.condition,
        ruleType: this.ruleType,
        familyArticle: this.familyArticle,
        supplier: this.supplier,
        familyClient: this.familyClient,
        start_date: this.startDate,
        end_date: this.endDate,
        search: this.search,
      };

      localStorage.setItem("PRICING_RULE_FILTERS", JSON.stringify(filters));

      axios
        .get(
          API_BASE_URL +
            "/pricing_rules" +
            "?page=" +
            (page || this.page) +
            "&items_per_page=" +
            (itemsPerPage != -1 ? itemsPerPage : this.itemsPerPage) +
            "&order_by=" +
            (sortBy[0] || this.sortBy) +
            "&order_direction=" +
            (this.sortOrd ?? "asc") +
            (this.ruleType ? "&rule_type=" + this.ruleType.id : "") +
            (this.condition ? "&condition=" + this.condition.id : "") +
            (this.familyArticle
              ? "&parent_also=1&family_article=" + this.familyArticle.id
              : "") +
            (this.supplier ? "&supplier=" + this.supplier.id : "") +
            (this.familyClient
              ? "&family_client=" + this.familyClient.id
              : "") +
            (this.startDate ? "&startDate=" + this.startDate : "") +
            (this.endDate ? "&endDate=" + this.endDate : "") +
            (this.search ? "&search=" + encodeURIComponent(this.search) : ""),
          {
            headers: this.header,
          }
        )
        .then(({ data }) => {
          this.pricingRules = data.data;
          this.lastPage = data.last_page;
          this.page = data.current_page;
          this.totalItems = data.meta.total;
          this.itemsPerPage = parseInt(data.per_page);
          this.isLoading = false;
          this.watchChanges = true;
        })
        .catch((err) => {
          this.isLoading = false;
          this.watchChanges = true;
        });
    },
    makeArrayOfPropertiesForTranslating() {},
    async getFamilyArticles() {
      await axios
        .get(API_BASE_URL + "/family_articles?items_per_page=-1", {
          headers: this.header,
        })
        .then(({ data }) => {
          this.globalfamilyArticles = data.data;

          this.globalfamilyArticles.forEach((familyArticle) => {
            var familyArticleProperties = [];
            let familyArticleId = familyArticle.id;
            if (familyArticle.parent_family_id) {
              familyArticle.parent_family_properties.forEach((property) => {
                var propertyName =
                  "property_" +
                  (property.property_id < 10
                    ? 0 + property.property_id.toString()
                    : property.property_id);
                familyArticleProperties[propertyName] = this.$t(
                  "articles.properties." + property.name
                );
                this.propertyNames[familyArticleId] = familyArticleProperties;
                this.propertyNames[
                  familyArticle.parent_family_id
                ] = familyArticleProperties;
              });
            } else {
              familyArticle.properties.forEach((property) => {
                var propertyName =
                  "property_" +
                  (property.property_id < 10
                    ? 0 + property.property_id.toString()
                    : property.property_id);
                familyArticleProperties[propertyName] = this.$t(
                  "articles.properties." + property.name
                );
                this.propertyNames[familyArticleId] = familyArticleProperties;
              });
            }
          });
        })
        .catch(function(error) {
          console.log("an error occured " + error);
        });
    },
    getSuppliers() {
      axios
        .get(
          API_BASE_URL +
            "/suppliers?items_per_page=-1&internal_supplier=true&parent_also=true",
          { headers: this.header }
        )
        .then(({ data }) => {
          this.suppliers = data.data;
        })
        .catch(function(error) {
          console.log("an error occured " + error);
        });
    },
    async getAllCodes() {
      await this.getCodes('codes', false)
                .then((data) => {
                  this.allCodes = data;
                });
    },
    async getConditions() {
      await this.getCodes('article_conditions', 'pricing_rule.conditions')
                .then((data) => {
                  this.conditions = data;
                });
    },
    async getRuleTypes() {
      await this.getCodes('pricing_rule_types', 'pricing_rule.rule_types')
                .then((data) => {
                  this.ruleTypes = data;
                });
    },
    editItem(item) {
      this.$store.commit("seteditId", item.id);
      this.$store.commit("setFormMode", EDIT_MODE);
      this.$router.push("/pricing-rules/edit").catch(() => {});
    },
    showImage(item) {
      alert("THE IMAGE");
    },
    deleteItem(item) {},
    updateSort(sortBy) {
      if (typeof sortBy == "object") {
        this.sortBy = sortBy[0];
      } else {
        this.sortBy = sortBy == undefined ? "id" : sortBy;
      }
    },
    updateSortOrder(sortDesc) {
      if (typeof sortDesc == "object") {
        !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      } else {
        !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      }
      console.log("console order => ", this.sortOrd);
      this.fetchData();
    },
    updatePagination(page) {
      this.page = page;
      this.fetchData(page);
    },
    updateItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage == -1 ? this.totalItems : itemsPerPage;
      this.fetchData();
      this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
    },
    applyPreviousFilters() {
      let applyOnRoutes = ["PricingRules", "EditPricingRule", "AddPricingRule"];
      if (applyOnRoutes.includes(localStorage.getItem("LS_ROUTE_KEY"))) {
        var pricingRuleFilters = JSON.parse(
          localStorage.getItem("PRICING_RULE_FILTERS")
        );

        if (pricingRuleFilters != null) {
          this.watchChanges = false;
          this.page = pricingRuleFilters.page;
          this.itemsPerPage = pricingRuleFilters.items_per_page;
          this.sortBy = pricingRuleFilters.order_by;
          this.sortDesc = pricingRuleFilters.order_direction;
          this.ruleType = pricingRuleFilters.ruleType;
          this.condition = pricingRuleFilters.condition;
          this.familyArticle = pricingRuleFilters.familyArticle;
          this.supplier = pricingRuleFilters.supplier;
          this.familyClient = pricingRuleFilters.familyClient;
          this.startDate = pricingRuleFilters.start_date;
          this.endDate = pricingRuleFilters.end_date;
          this.search = pricingRuleFilters.search;

          this.fetchData();
        }
      }
    },
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    headers() {
      return [
        { text: "ID", value: "id", sortable: true, width: "70" },
        {
          text: this.$t("pricing_rule.rule_type"),
          align: "start",
          sortable: true,
          value: "rule_type_id",
          width: "120",
        },
        {
          text: this.$t("pricing_rule.condition"),
          align: "start",
          sortable: true,
          value: "condition_id",
          width: "115",
        },
        {
          text: this.$t("pricing_rule.family_article"),
          sortable: true,
          value: "family_article_id",
          width: "140",
        },
        {
          text: this.$t("pricing_rule.name"),
          align: "start",
          sortable: true,
          value: "name",
          width: "200",
        },
        {
          text: this.$t("pricing_rule.supplier"),
          sortable: true,
          value: "supplier_id",
          width: "120",
        },
        {
          text: this.$t("pricing_rule.family_client"),
          sortable: true,
          value: "family_client_id",
          width: "150",
        },
        {
          text: this.$t("pricing_rule.filters"),
          sortable: false,
          value: "filters",
          width: "120",
        },
        {
          text: this.$t("pricing_rule.amount"),
          value: "amount",
          sortable: true,
          width: "120",
        },
        {
          text: this.$t("pricing_rule.percent"),
          value: "percent",
          sortable: true,
          width: "120",
        },
        {
          text: this.$t("pricing_rule.start_date"),
          value: "start_date",
          sortable: true,
          width: "120",
        },
        {
          text: this.$t("pricing_rule.end_date"),
          value: "end_date",
          sortable: true,
          width: "120",
        },
        {
          text: this.$t("pricing_rule.active"),
          value: "is_active",
          sortable: true,
          width: "120",
        },
      ];
    },
  },
  watch: {
    date(val) {
      this.endDate = this.formatDate(this.date);
      this.startDate = this.formatDate(this.date);
    },
    familyArticle: {
      handler: function() {
        if (this.watchChanges) {
          this.fetchData();
        }
      },
    },
    condition: {
      handler: function() {
        if (this.watchChanges) {
          this.fetchData();
        }
      },
    },
    supplier: {
      handler: function() {
        if (this.watchChanges) {
          this.fetchData();
        }
      },
    },
    startDate: {
      handler: function() {
        if (this.watchChanges) {
          this.fetchData();
        }
      },
    },
    endDate: {
      handler: function() {
        if (this.watchChanges) {
          this.fetchData();
        }
      },
    },
    ruleType: {
      handler: function() {
        if (this.watchChanges) {
          this.fetchData();
        }
      },
    },
    familyClient: {
      handler: function() {
        if (this.watchChanges) {
          this.fetchData();
        }
      },
    },
  },
};
</script>

<style scoped>
.to-upper input {
  text-transform: uppercase !important;
}
.greyRow {
  background-color: rgb(221, 221, 221);
}
</style>
