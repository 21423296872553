import {API_BASE_URL, DEFAULT_ITEMS_PER_PAGE_OPTIONS} from "@/config";
import axios from "@/plugins/axios";

export default {
    data() {
        return {
            employees: [],
            employeeRequests: [],
            employeeCounters: [],
            employmentCounters: [],
            employeeContracts: [],
            employmentContracts: [],
            clockingTypes: [],
            employmentContractTypes: [],
            axiosOptions: {Authorization: "Bearer " + this.$store.state.AccessToken},
            perPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
            total : 0,
            page: 1,
            perPageOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
            timerId: null,
            searching: false,
        }
    },
    computed: {
        formattedEmployees() {
            return this.employees.map((item, index) => {
                return {
                    ...item,
                    serial: index + 1,
                    text: item.last_name + ' ' + item.first_name + (item.badge ? ' (' + item.badge  + ')' : ''),
                }
            })
        },
    },
    methods: {
        translate(key, params = {}) {
            return this.$t('clocking_section.' + key, params)
        },
        getEmployees(search = null, withEmploymentContract = false) {
            return new Promise((resolve, reject) => {
                let url = API_BASE_URL + '/employees?page=' + this.page + '&per_page=' + this.perPage
                if(search){
                    url += '&search=' + encodeURIComponent(search)
                }

                if(withEmploymentContract){
                    url += '&with_employment_contract=1'
                }

                axios.get(url, {headers: this.axiosOptions})
                    .then(response => {
                        if(response.data.data.total) {
                            this.total = response.data.data.total
                        }
                        let result = response.data.data?.data || response.data.data;
                        resolve(Object.values(result));
                    })
            })
        },
        getEmployeeRequests(filters = []) {
            return new Promise((resolve) => {
                let url = API_BASE_URL + '/employees/request?filterable=1'
                for (const filterKey in filters) {
                    if( this.isset(filters[filterKey])) {
                        url += '&' + filterKey + '=' + filters[filterKey]
                    }
                }
                
                axios.get(url, {headers: this.axiosOptions})
                    .finally(() => {})
                    .then(response => {
                        resolve(response.data.data);
                    })
            })
        },

        getEmployeeContracts() {
            return new Promise((resolve) => {
                let url = API_BASE_URL + '/employees/contracts?page=' + this.page + '&per_page=' + this.perPage
                
                axios.get(url, {headers: this.axiosOptions})
                    .finally(() => {})
                    .then(response => {
                        this.total = response.data.data.total
                        resolve(response.data.data?.data || response.data.data);
                    })
            })
        },
        getEmploymentContracts(){
            return new Promise((resolve, reject) => {
                let url = API_BASE_URL + '/employment/contracts'
                
                axios.get(url, {headers: this.axiosOptions})
                    .finally(() => {})
                    .then((response) => resolve(response.data.data))

            })
        },
        getEmployeeCounters() {
            return new Promise((resolve) => {
                let url = API_BASE_URL + '/employees/counters'
                
                axios.get(url, {headers: this.axiosOptions})
                    .finally(() => {})
                    .then(response => {
                        resolve(response.data.data);
                    })
            })
        },
        getEmploymentCounters() {
            return new Promise((resolve) => {
                let url = API_BASE_URL + '/employment/counters'
                
                axios.get(url, {headers: this.axiosOptions})
                    .finally(() => {})
                    .then(response => {
                        resolve(response.data.data);
                    })
            })
        },
        getClockingTypes(){
                return  this.getCodes('clocking_types', false);
        },
        getEmploymentContractTypes(){
            return this.getCodes('employment_contract_types', false)
        },
       async getMeasurementUnits(){
            this.measurementUnits = (await this.getCodes('measurement_units', false)).map((item) => {
                return {
                    ...item,
                    text: this.$t('measurement_units.' + item.key),
                }
            }).filter((item) => ['hour', 'day', 'second', 'month', 'year', 'week', 'minute'].includes(item.key));
        }
    }
}