<template>
  <div>
    <supplier-connection-form></supplier-connection-form>
  </div>
</template>
<script>
import SupplierConnectionForm from "@/components/CompanySupplierConnection/SupplierConnectionForm";
export default {
  name: "SupplierConnection",
  components: {
    SupplierConnectionForm
  },
}
</script>