<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col class="pa-0 ma-0" cols="12" sm="9"> </v-col>
        <v-col class="pa-0 ma-0" cols="12" sm="3">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            class="mr-3 to-upper"
            autofocus
            prepend-icon="mdi-magnify"
            v-bind:clearable="true"
            clear-icon="mdi-close"
            v-model="searchClientDocumentRecurrence"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-data-table
          fixed-header
          :footer-props="{
            'items-per-page-options': dataTableOptions,
          }"
          :headers="headers"
          :options="options"
          :server-items-length="totalItems"
          :loading-text="this.$t('loading')"
          class="elevation-0"
          :height="'calc(-220px + 100vh)'"
          style="width: 100%"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items-per-page="itemsPerPage"
          item-key="id"
          @update:sort-by="updateSort"
          @update:sort-desc="updateSortOrder"
          @update:page="updatePagination"
          @update:items-per-page="updateItemsPerPage"
          :items="client_document_recurrence"
          must-sort
          :key="forceReloadComponentKey"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td @click="editClientDocumentRecurrence(item)">
                {{ item.client.full_name }}
              </td>
              <td @click="editClientDocumentRecurrence(item)">
                {{ item.recurrence_document.name }}
              </td>
              <td @click="editClientDocumentRecurrence(item)">
                {{ item.start_at }}
              </td>
              <td @click="editClientDocumentRecurrence(item)">
                {{ item.end_at }}
              </td>
              <td @click="editClientDocumentRecurrence(item)">
                {{ item.run_day }}
              </td>
              <td @click="editClientDocumentRecurrence(item)">
                {{ item.run_month }}
              </td>
              <td @click="editClientDocumentRecurrence(item)">
                {{ item.counter }}
              </td>
              <td @click="editClientDocumentRecurrence(item)">
                {{ item.last_run }}
              </td>
              <td @click="editClientDocumentRecurrence(item)">
                <template v-if="item.last_run_status">
                  {{ $t('client_recurrence_document.last_run_status_codes.'+ item.last_run_status.key) }}
                </template>
              </td>
              <td @click="editClientDocumentRecurrence(item)">
                <template v-if="item.client_notification_type">
                  {{ $t('scheduler.notification_types.'+ item.client_notification_type.key) }}
                </template>
              </td>
              <td @click="editClientDocumentRecurrence(item)">
                <span v-if="item.is_active">
                  <v-icon color="success" class="mr-1">
                    mdi-check
                  </v-icon>
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL, EDIT_MODE, VIEW_MODE } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";

export default {
  name: "User",
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      client_document_recurrence: [],
      searchClientDocumentRecurrence: null,
      isLoading: true,
      totalItems: 0,
      lastPage: 1,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      clients: [],
      options: {},
      sortBy: "id",
      sortDesc: false,
      sortOrd: "ASC",
      sort: {},
      showAlert: false,
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      search: null,
      autocompleteValueLocal: "off",
    };
  },

  mounted() {
    this.$store.commit("setFormMode", VIEW_MODE);
    this.fetchData();
    this.autocompleteValueLocal = this.autocompleteValue();
  },
  methods: {
    fetchData(currentPage = null) {
      this.resetDataTable(currentPage);
      
      axios
        .get(
          API_BASE_URL +
            "/client_document_recurrences" +
            "?page=" +
            (this.page ?? 1) +
            (this.searchClientDocumentRecurrence ? "&search=" + encodeURIComponent(this.searchClientDocumentRecurrence) : "") +
            (this.itemsPerPage ? "&items_per_page=" + this.itemsPerPage : "") +
            (this.sortBy ? "&sortby=" + this.sortBy : "") +
            (this.sortOrd ? "&sortOrd=" + this.sortOrd : "") + 
            ("&is_listing=true"),
          { headers: this.header }
        )
        .then((client_document_recurrence) => {
          this.client_document_recurrence = client_document_recurrence.data.data;
          console.log("client doucment recurrence", client_document_recurrence.data);
          this.lastPage = client_document_recurrence.data.meta.last_page;
          this.totalItems = client_document_recurrence.data.meta.total;
          this.page = client_document_recurrence.data.meta.current_page;
        })
        .catch((error) => {})
        .finally(() => {
          
        });
    },
    updatePagination(page) {
      this.page = page;
      this.fetchData(page);
    },
    updateItemsPerPage(itemsPerPage) {
      console.log(itemsPerPage);
      itemsPerPage == -1
        ? (this.itemsPerPage = this.totalItems)
        : (this.itemsPerPage = itemsPerPage);
      this.fetchData();
    },
    editClientDocumentRecurrence(item) {
      this.$store.commit("seteditId", item.id);
      this.$store.commit("setFormMode", EDIT_MODE);
      this.$router.push("/client_recurrence_document/edit").catch(() => {});
    },
    updateSort(sortBy) {
      if (typeof sortBy == "object") {
        this.sortBy = sortBy[0];
      } else {
        this.sortBy = sortBy;
      }
      this.fetchData();
    },
    updateSortOrder(sortDesc) {
      if (typeof sortDesc == "object") {
        !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      } else {
        !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
      }
      this.fetchData();
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("client_recurrence_document.client_name"),
          value: "name",
          sortable: true,
          width: 150,
        },
        {
          text: this.$t("client_recurrence_document.recurrence_name"),
          value: "name",
          sortable: true,
        },
        {
          text: this.$t("client_recurrence_document.start_at"),
          value: "name",
          sortable: true,
        },
        {
          text: this.$t("client_recurrence_document.end_at"),
          value: "name",
          sortable: true,
        },
        {
          text: this.$t("client_recurrence_document.run_day"),
          value: "name",
          sortable: true,
        },
        {
          text: this.$t("client_recurrence_document.run_month"),
          value: "name",
          sortable: true
        },
        {
          text: this.$t("client_recurrence_document.counter"),
          value: "document_type",
          sortable: true
        },
        {
          text: this.$t("client_recurrence_document.last_run_date"),
          value: "document_type",
          sortable: true
        },
        {
          text: this.$t("client_recurrence_document.last_run_status"),
          value: "document_type",
          sortable: true
        },
        {
          text: this.$t("client_recurrence_document.client_notification_type"),
          value: "document_type",
          sortable: true
        },
        {
          text: this.$t("client_recurrence_document.is_active"),
          value: "document_type",
          sortable: true
        },
      ];
    },
  },
  watch: {
    searchClientDocumentRecurrence: _.debounce(function(e) {
      this.fetchData();
    }, 500),
  },
};
</script>
<style scoped></style>
