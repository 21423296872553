<template>
    <v-card class="elevation-0">
        <div>
            <v-col cols="12" sm="12" md="12" class="ma-0 pa-0">
                <v-text-field :autocomplete="autocompleteValueLocal" v-model="searchReport" class="to-upper ml-0"
                    :placeholder="$t('report.placeholder_search_report')" append-icon="mdi-close"
                    v-on:keydown="handleInput($event, 'alphanumeric', 20);" v-on:keydown.enter.prevent=""
                    @input="debounceInput" @click:append="searchReport = null; getReports()" :outlined="is_boxes"
                    :dense="is_boxes"
                ></v-text-field>
            </v-col>
        </div>
        <div>
            <v-list two-line>
                <v-list-item-group v-model="selected_index" return-object active-class="selected_report">
                    <template v-for="(item) in reports" >
                        <v-list-item :key="item.id">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ item.name }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </template>
                </v-list-item-group>
            </v-list>
        </div>
    </v-card>
</template>
<script>
import axios from '@/plugins/axios';
import _ from 'lodash';
import { API_BASE_URL } from "@/config";

export default {
    name: "Report",
    props: {
        selected: [Object]
    },
    data() {
        return {
            is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
            token: this.$store.state.AccessToken,
            header: { Authorization: 'Bearer ' + this.$store.state.AccessToken },
            autocompleteValueLocal: 'off',
            reports: [],
            searchReport: null,
            selected_index: null,
            isDebounceCancelled: false
        }
    },
    mounted() {
        this.header = { Authorization: 'Bearer ' + this.token };
        this.autocompleteValueLocal = this.autocompleteValue();
        this.getReports(true);
    },
    methods: {
        debounceInput: _.debounce(function (e) {
            if (this.isDebounceCancelled) {
                this.isDebounceCancelled = false;
                return false;
            }
            this.getReports();
        }, 500),
        getReports(autoSelectFirst = false) {
            
            this.selected_index = null;
            axios.get(API_BASE_URL + '/reports' + "?page=1" + "&items_per_page=20" +
                (this.searchReport ? ('&search=' + encodeURIComponent(this.searchReport)) : '')
                , { headers: this.header }
            )
                .then(({ data }) => {
                    console.log("report", data);
                    this.reports = data.data;
                    if (autoSelectFirst) {
                        this.selected_index = 0;
                    }
                })
                .catch(function (error) {
                    console.log('an error occured ' + error);
                })
                .finally(() => {
                    
                });
        },
    },
    watch: {
        selected_index() {
            this.$emit('update:selected', this.reports[this.selected_index]);
        },
    }
}
</script>
<style scoped>

.selected_report {
    background-color: rgb(230, 230, 230) !important;
}
</style>