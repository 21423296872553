<template>
  <v-card>
    <v-tabs
        v-model="tab"
        background-color="light-blue darken-2"
        dark
        align-with-title
        center-active
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#client-addresses">
        <v-icon>mdi-cash-usd</v-icon>
        {{ this.$t('pricing_rule.filters') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
          :key="'client-addresses'"
          :value="'client-addresses'"
      >
        <v-card flat>
          <company-clients-addresses-tab :client="client"></company-clients-addresses-tab>
        </v-card>
      </v-tab-item>
      <v-tab-item
          :key="'client-bills'"
          :value="'client-bills'"
      >
        <v-card flat>
          <company-clients-bills-tab :client="client"></company-clients-bills-tab>
        </v-card>
      </v-tab-item>
      <v-tab-item
          :key="'client-cars'"
          :value="'client-cars'"
      >
        <v-card flat>
          <!-- <company-clients-vehicle-tab></company-clients-vehicle-tab> -->
        </v-card>
      </v-tab-item>
      <v-tab-item
          :key="'client-schedules'"
          :value="'client-schedules'"
      >
        <v-card flat>
          <v-card-text>Client: {{ client }} schedules: {{ text }}</v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
// import CompanyClientAddressesTab from '@/components/CompanyClientAddressesTab'

export default {
  name:       "PricingRuleTabs",
  components: {
    // 'company-clients-addresses-tab': CompanyClientAddressesTab,
  },
  props:      [
    'client'
  ],
  data() {
    return {
      tab:  null,
      text: 'To be implemented',
    }
  },
  computed: {}
}
</script>

<style scoped>

</style>
