var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",staticStyle:{"width":"100%"},attrs:{"fixed-header":"","footer-props":{
        'items-per-page-options': _vm.dataTableOptions,
    },"hide-default-footer":true,"headers":_vm.headers,"items":_vm.expeditions,"options":_vm.options,"loading-text":this.$t('loading'),"items-per-page":_vm.itemsPerPage,"item-key":"identifier","mustSort":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('tr',{staticClass:"itemrow",class:_vm.setExpeditionRowColorClass(item.status)},[_c('td',{staticClass:"expeditionitemtd",on:{"click":function($event){return _vm.editExpedition(item)}}},[_c('v-row',[_vm._v(" "+_vm._s(item.identifier)+" ")]),_c('v-row')],1),_c('td',{staticClass:"expeditionitemtd",on:{"click":function($event){return _vm.editExpedition(item)}}},[_c('v-row',{staticClass:"expeditorline"},[(item.expeditor)?[(item.expeditor.name.length > 25)?[_c('v-tooltip',{attrs:{"bottom":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.expeditor.name.substr(0, 25))+"... ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.expeditor.name)+" ")])]:[_vm._v(" "+_vm._s(item.expeditor.name)+" ")],(item.expeditor.tva)?_c('span',[_vm._v(" ("+_vm._s(item.expeditor.tva)+") ")]):_vm._e()]:_vm._e()],2),_c('v-row',{staticClass:"transporterline"},[(item.transporter)?[(item.transporter.name.length > 25)?[_c('v-tooltip',{attrs:{"bottom":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.transporter.name.substr(0, 25))+"... ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.transporter.name)+" ")])]:[_vm._v(" "+_vm._s(item.transporter.name)+" ")],(item.transporter.tva)?_c('span',[_vm._v(" ("+_vm._s(item.transporter.tva)+") ")]):_vm._e()]:_vm._e()],2)],1),_c('td',{staticClass:"expeditionitemtd",on:{"click":function($event){return _vm.editExpedition(item)}}},[_c('v-row',{staticClass:"expeditorline"},[(item.chargings && item.chargings.length > 0 && item.chargings[0].address)?[[_c('v-tooltip',{attrs:{"bottom":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.computeExpeditionListingAddressLabel(item.chargings[0].address))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.chargings[0].address.human_readable_address)+" ")])]]:_vm._e()],2),_c('v-row',{staticClass:"transporterline"},[(item.dischargings && item.dischargings.length > 0 && item.dischargings[0].address)?[[_c('v-tooltip',{attrs:{"bottom":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.computeExpeditionListingAddressLabel(item.dischargings[0].address))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.dischargings[0].address.human_readable_address)+" ")])]]:_vm._e()],2)],1),_c('td',{staticClass:"expeditionitemtd",on:{"click":function($event){return _vm.editExpedition(item)}}},[_c('v-row',[(item.chargings && item.chargings.length > 0)?[(item.chargings[0].article.original_name.length > 25)?[_c('v-tooltip',{attrs:{"bottom":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.chargings[0].article.original_name.substr(0, 25))+"... ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.chargings[0].article.original_name)+" ")])]:[_vm._v(" "+_vm._s(item.chargings[0].article.original_name)+" ")],_vm._v(" ("+_vm._s(item.chargings[0].quantity)+") ")]:_vm._e()],2),_c('v-row')],1),_c('td',{staticClass:"expeditionitemtd",on:{"click":function($event){return _vm.editExpedition(item)}}},[_c('v-row',{staticClass:"expeditorline"},[_vm._v(" "+_vm._s(item.truck_registration_number)+" ")]),_c('v-row',{staticClass:"transporterline"},[_vm._v(" "+_vm._s(item.trolly_registration_number)+" ")])],1),_c('td',{staticClass:"expeditionitemtd",on:{"click":function($event){return _vm.editExpedition(item)}}},[_c('v-row',{staticClass:"expeditorline"},[(item.expeditor_price)?[_vm._v(" "+_vm._s(_vm.currencySymbol)+" "+_vm._s(item.expeditor_price)+" ")]:_vm._e()],2),_c('v-row',{staticClass:"transporterline"},[(item.transport_price)?[_vm._v(" "+_vm._s(_vm.currencySymbol)+" "+_vm._s(item.transport_price)+" ")]:_vm._e()],2)],1),_c('td',{staticClass:"expeditionitemtd",on:{"click":function($event){return _vm.editExpedition(item)}}},[_c('v-row',[(item.user)?[_vm._v(" "+_vm._s(item.user.name)+" ")]:_vm._e()],2),_c('v-row')],1),_c('td',{staticClass:"expeditionitemtd",on:{"click":function($event){return _vm.editExpedition(item)}}},[_c('v-row',[(item.status && item.status.key)?[_vm._v(" "+_vm._s(_vm.$t('expedition_statuses.' + item.status.key))+" ")]:_vm._e()],2),_c('v-row')],1),_c('td',[(item.attachments && item.attachments.length > 0)?[_c('div',[(item.attachments.length)?_c('ul',{staticClass:"scroll pl-0"},_vm._l((item.attachments),function(file){return _c('li',{key:file,staticClass:"list-item"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{staticClass:"attachment_icon_color",attrs:{"right":"","medium":""},on:{"click":function($event){return _vm.viewAttachment(file)}}},[_vm._v(" "+_vm._s(_vm.findFileIconWithDescription(file))+" ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(file.title)+" ")])],1)}),0):_vm._e()])]:_vm._e()],2),_c('td',{staticClass:"expeditionitemtd",on:{"click":function($event){return _vm.editExpedition(item)}}},[_c('v-row',[(item.note)?[_c('v-tooltip',{attrs:{"right":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v(" mdi-note-alert ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.note)+" ")])]:_vm._e()],2),_c('v-row')],1)])]}}])},[_c('template',{slot:"no-data"},[_c('div')])],2)}
var staticRenderFns = []

export { render, staticRenderFns }