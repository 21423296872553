<template>
    <div class="mt-8">
        <!-- First Row (3 items) -->
        <v-row>
            <v-col
                class="ma-0 pa-0 pl-4"
                v-for="(property, index) in getPropertiesForSection(
                    prop_company_properties,
                    'email'
                ).slice(0, 5)"
                :key="index"
            >
                <v-text-field
                    v-if="!checkIfPropertyIsEmailSignature(property)"
                    :type="isPasswordField(property) ? 'password' : 'text'"
                    :label="getTranslation(property.company_setting_key)"
                    v-model="property.value"
                ></v-text-field>
            </v-col>
        </v-row>

        <!-- Second Row (2 items) -->
        <v-row>
            <v-col
                class="ma-0 pa-0 pl-4"
                v-for="(property, index) in getPropertiesForSection(
                    prop_company_properties,
                    'email'
                ).slice(5, 7)"
                :key="index"
                cols="6"
            >
                <v-text-field
                    v-if="!checkIfPropertyIsEmailSignature(property)"
                    :type="isPasswordField(property) ? 'password' : 'text'"
                    :label="getTranslation(property.company_setting_key)"
                    v-model="property.value"
                ></v-text-field>
            </v-col>
        </v-row>

        <!-- Third Row (2 items) -->
        <v-row>
            <v-col
                class="ma-0 pa-0 pl-4"
                v-for="(property, index) in getPropertiesForSection(
                    prop_company_properties,
                    'email'
                ).slice(7, 9)"
                :key="index"
                cols="6"
            >
                <v-text-field
                    v-if="!checkIfPropertyIsEmailSignature(property)"
                    :type="isPasswordField(property) ? 'password' : 'text'"
                    :label="getTranslation(property.company_setting_key)"
                    v-model="property.value"
                ></v-text-field>
            </v-col>
        </v-row>

        <!-- Separate Row for NoteEditor -->
        <v-row
            v-for="(property, index) in getPropertiesForSection(
                prop_company_properties,
                'email'
            )"
            :key="'note-' + index"
        >
            <v-col
                class="ma-0 pa-0 pl-4"
                id="email_pdf"
                cols="12"
                sm="12"
                v-if="checkIfPropertyIsEmailSignature(property)"
            >
                <h4 class="ma-4 ml-0">{{ getTranslation(property.company_setting_key) }}</h4>
                <NoteEditor
                    :label="getTranslation(property.company_setting_key)"
                    v-model="property.value"
                    :showLabel="false"
                />
            </v-col>
        </v-row>
    </div>
</template>
<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import { required, email, requiredIf } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import NoteEditor from "@/components/NoteEditor.vue";

export default {
    mixins: [validationMixin],
    name: "CompanyEmails",
    props: {
        prop_company_properties: [Array],
    },
    components: { NoteEditor },
    data() {
        return {
            is_boxes: this.getCompanyPropertyFromLocalStorage(
                "boxes_on_fields",
                1
            ),
            autocompleteValueLocal: "off",

            header: {
                Authorization: "Bearer " + this.$store.state.AccessToken,
            },
            token: this.$store.state.AccessToken,
        };
    },
    created() {
        this.header = { Authorization: "Bearer " + this.token };
        this.autocompleteValueLocal = this.autocompleteValue();
    },
    mounted() {
        this.header = { Authorization: "Bearer " + this.token };
    },
    methods: {
        getTranslation(company_key){
            if(company_key){
                return this.$t("company_properties." + company_key.toLowerCase());
            }
            return company_key.toLowerCase().replaceAll("_", " ");
        },
        checkIfPropertyIsEmailSignature(property) {
            return property.company_setting_key == "COMPANY_EMAIL_SIGNATURE";
        },
        updatePropData() {
            this.$emit(
                "update:prop_company_properties",
                this.prop_company_properties
            );
        },
        checkPropertyAvailable(property_name) {
            let isAvailable = false;
            if (
                this.prop_company_properties &&
                this.prop_company_properties.length > 0
            ) {
                this.prop_company_properties.forEach((element) => {
                    if (
                        element.company_setting_key.toLowerCase() ==
                        property_name.toLowerCase()
                    ) {
                        isAvailable = true;
                    }
                });
            }
            return isAvailable;
        },
    },
    computed: {
        send_mail_from_address_is_available() {
            return this.checkPropertyAvailable("SEND_MAIL_FROM_ADDRESS");
        },
    },
    watch: {
        shipping_search_country() {
            this.updatePropData();
        },
    },
};
</script>
<style>
.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
}
</style>
