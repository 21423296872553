<template>
    <v-btn class="ma-2" small icon :style="{backgroundColor: 'green', display: 'inline-flex'}">
        <v-icon size="small" color="white">mdi mdi-square-edit-outline</v-icon>
    </v-btn>
</template>
<script>
    export default {
        
    }
</script>
