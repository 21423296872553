<template>
  <v-dialog :value="value" max-width="800">
    <v-card v-if="email">
      <v-card-title>
        <h3 class="headline mb-0"><span v-if="isset(email.resource) && isset(email.resource.identifier)">{{email.resource.identifier}} -</span> {{ email.subject }}</h3>

        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>

        <v-simple-table>
          <template v-slot:default>
            <tbody>
            <tr>
              <td class="font-weight-bold">{{ translate('from') }}</td>
              <td>{{ email.user.name }}</td>
              <td class="font-weight-bold">{{ translate('to') }}</td>
              <td>{{ email.to }}</td>
            </tr>
            <tr v-if="isset(email.cc) || isset(email.bcc)">
              <td v-if="email.cc" class="font-weight-bold">{{ translate('cc') }}</td>
              <td :colspan="isset(email.bcc) ? 1 : 3" v-if="email.cc">{{ email.cc }}</td>
              <td v-if="isset(email.bcc)" class="font-weight-bold">{{translate('bcc')}}</td>
              <td v-if="isset(email.bcc)" :colspan="isset(email.cc) ? 1 : 3">{{ email.bcc }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">{{ translate('subject') }}</td>
              <td colspan="3">{{ email.subject }}</td>
            </tr>

            <tr v-if="isset(email.resource) && isset(email.resource.identifier)">
              <td class="font-weight-bold">{{translate('reference')}}</td>
              <td colspan="3">{{ email.resource.identifier }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">{{ translate('date') }}</td>
              <td>{{ formatDateTime(email.created_at) }}</td>
              <td class="font-weight-bold">{{ translate('status') }}</td>
              <td>{{ translate('statuses.' + email.status.key) }}</td>
            </tr>
            <tr>
              <td colspan="4">
                <h3 class="mt-2 mb-1">{{ translate('message') }}</h3>
                <p v-html="email.message"></p>
              </td>
            </tr>
            <tr v-if="isset(email.error_message)">
              <td colspan="4">
                <h3 class="mt-2 mb-1">{{ translate('error_message') }}</h3>
                <p v-html="email.error_message"></p>
              </td>
            </tr>
            <tr v-if="isset(email.attachments)">
              <td>{{ translate('attachments') }}</td>
              <td colspan="3">
                {{ translatedDocuments }}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey-lighten-2" @click="close()" >
          {{ $t("close") }}
          <v-icon dark right> mdi-keyboard-return</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "@/plugins/axios";
import {API_BASE_URL} from "@/config";

export default {
  name: 'EmailDetail',
  emits: ['input'],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    emailId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      email: null,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
      },
    }
  },
  computed:{
    translatedDocuments(){
      if(!this.isset(this.email?.attachments)){
        return "";
      }
      return this.email?.attachments.split(',').map((item) => {
        return this.translationHelper('send_pdf_via_email.documents.' + item)
      }).join(', ')
    }
  },
  watch:{
    async value(){
      if(!this.value){
        return false
      }

     await this.getEmail(this.emailId)
    }
  },
  methods: {
    close() {
      this.$emit('input', false)
    },
    getEmail(id) {
      return new Promise((resolve, reject) => {
        
        axios.get(API_BASE_URL + '/emails/' + id, {headers: this.headers})
            .finally(() => {
              
            })
            .then(response => {
              this.email = response.data.data
              resolve(response.data.data)
            })
            .catch(error => {
              reject(error)
            })
      })
    },
    translate(key) {
      return this.$t('emails_section.' + key);
    },
  }
}
</script>