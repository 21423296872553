var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"1224px","scrollable":""},on:{"click:outside":function($event){return _vm.closeStocks()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeStocks()}},model:{value:(_vm.modify_stock_dialog),callback:function ($$v) {_vm.modify_stock_dialog=$$v},expression:"modify_stock_dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("stocks.modify_stock.title")))])]),_c('v-card-text',[_c('v-container',{staticClass:"pt-0"},[_c('v-row',{staticClass:"mb-n8"},[_c('v-col',[_c('p',{staticClass:"orderTitle"},[_vm._v(_vm._s(_vm.rowData.description))])])],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',[_c('p',{staticClass:"orderSubTitle"},[_vm._v(" "+_vm._s(_vm.rowData.identifier)+" ")])])],1),_c('v-row',{staticClass:"pt-4"},[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('custom-money-input',{ref:"reserve",staticClass:"mr-2 to-upper right-aligned-input",attrs:{"autocomplete":_vm.autocompleteValueLocal,"hide-details":"auto","label":this.$t('stocks.int_stock'),"tabindex":"2","properties":{
                readonly: false,
                disabled: false,
                outlined: false,
                placeholder: '0',
                dense: false,
                hideDetails: 'auto',
              },"options":{
                locale: _vm.userLanguageId,
                length: 10,
                precision: _vm.selectedFamily.quantity_decimals,
                empty: _vm.fixDecimal(0, _vm.selectedFamily.quantity_decimals),
              }},on:{"keydown":function($event){return _vm.handleInput($event, 'float', 8)}},model:{value:(_vm.form.stock),callback:function ($$v) {_vm.$set(_vm.form, "stock", $$v)},expression:"form.stock"}})],1),_c('v-col',{staticClass:"pt-9 pl-0 pr-0",attrs:{"cols":"24","sm":"0"}},[_c('h2',[_vm._v(" / ")])]),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('custom-money-input',{ref:"reserve",staticClass:"mr-2 to-upper text-red right-aligned-input",attrs:{"autocomplete":_vm.autocompleteValueLocal,"hide-details":"auto","label":this.$t('stocks.reserve'),"tabindex":"2","properties":{
                readonly: false,
                disabled: false,
                outlined: false,
                placeholder: '0',
                dense: false,
                hideDetails: 'auto',
              },"options":{
                locale: _vm.userLanguageId,
                length: 10,
                precision: _vm.selectedFamily.quantity_decimals,
                empty: _vm.fixDecimal(0, _vm.selectedFamily.quantity_decimals),
              }},on:{"keydown":function($event){return _vm.handleInput($event, 'float', 8)}},model:{value:(_vm.form.reserve),callback:function ($$v) {_vm.$set(_vm.form, "reserve", $$v)},expression:"form.reserve"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field-percent',{staticClass:"mr-2 to-upper right-aligned-input",attrs:{"label":this.$t('stocks.price'),"properties":{
                readonly: false,
                disabled: false,
                outlined: false,
                clearable: false,
                placeholder: '',
              },"options":{
                locale: _vm.userLanguageId,
                length: 12,
                precision: _vm.getDecimalNumber(),
                empty: null,
              }},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}})],1),(
              _vm.selectedFamily.parent_family_id == 1 || _vm.selectedFamily.id == 1
            )?_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"disabled":_vm.loadingDots,"color":_vm.sumOfDots() != _vm.form.stock ? 'warning' : 'primary'},on:{"click":function($event){return _vm.showModifyDotDialog(_vm.rowData)}}},[_vm._v(" "+_vm._s(_vm.$t("dot"))+" "),(!_vm.loadingDots)?[(_vm.sumOfDots() == _vm.form.stock)?_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-circle-opacity")]):_vm._e(),(_vm.sumOfDots() != _vm.form.stock)?_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-alert-circle-outline")]):_vm._e()]:_vm._e(),(_vm.loadingDots)?_c('v-progress-circular',{attrs:{"size":"20","width":3,"indeterminate":"","color":"grey lighten-5"}}):_vm._e()],2)],1):_vm._e(),_c('v-col',[_c('v-switch',{staticClass:"mt-4 ml-1",attrs:{"color":"success","label":_vm.$t('stocks.verified'),"inset":""},model:{value:(_vm.form.is_verified),callback:function ($$v) {_vm.$set(_vm.form, "is_verified", $$v)},expression:"form.is_verified"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('reserved-stock-table',{attrs:{"articleId":_vm.article_id,"familyArticleId":_vm.rowData.family_id,"activeTab":_vm.activeTab}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pr-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey lighten-2"},on:{"click":function($event){return _vm.closeStocks()}}},[_vm._v(" "+_vm._s(_vm.$t("stocks.cancel"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-keyboard-return")])],1),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.saveStocks()}}},[_vm._v(" "+_vm._s(_vm.$t("stocks.save"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-content-save")])],1)],1)],1),(_vm.modifyDots)?_c('stock-article-dot',{attrs:{"article":_vm.rowData,"passed_dot_items":_vm.dot_items,"total_stocks":_vm.form.stock},on:{"change-modify-article-dialog":function($event){return _vm.changeModifyArticleDialog()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }