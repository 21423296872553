<template>
    <v-row>
        <v-col cols="12" sm="3">
            <v-card class="elevation-0">
                <v-list one-line>
                    <v-list-item-group
                        v-model="selected_index"
                        :return-object="true"
                        active-class="selected_customer"
                    >
                        <template>
                            <v-list-item
                                v-for="item in translations"
                                :key="item.id"
                            >
                                <template>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ fixTitle(item.key) }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                        </template>
                    </v-list-item-group>
                </v-list>
            </v-card>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            v-if="selected_index != null && selected_index >= 0"
        >
            <h2 class="selected_item_header">
                {{ fixTitle(selected_item.key) }}
            </h2>
            <v-row v-for="(language, index) in company_languages" :key="index">
                <v-col cols="12" sm="12" class="pb-0">
                    <h3>{{ $t("appLanguage."+ language) }}</h3>
                </v-col>
                <v-col cols="12" sm="12">
                    <NoteEditor
                        :label="$t('scheduler.note')"
                        v-model="selected_item[language]"
                        :showLabel="false"
                        :rows="1"
                    />
                </v-col>
            </v-row>
        </v-col>

        <v-btn
            @click="onSubmit()"
            class="d-none"
            id="submit-translation-listing"
        ></v-btn>
    </v-row>
</template>
<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import NoteEditor from "@/components/NoteEditor.vue";
import { UPDATE_MODE } from "@/config";

export default {
    components: {
        NoteEditor,
    },
    data() {
        return {
            selected_index: null,
            translations: [],
            company_languages: [],
        };
    },
    mounted() {
        this.$store.commit("setFormMode", UPDATE_MODE);
        this.getTranslations().then(() => {
            this.$store.dispatch("fetchCompanyLanguages").then((data) => {
                console.log("fetch company language", data);
                this.company_languages = data.map((element) => {
                    return element.language_id;
                });
            });
        });
    },
    computed: {
        selected_item() {
            if (this.selected_index >= 0) {
                return this.translations[this.selected_index];
            }
            return null;
        },
    },
    methods: {
        fixTitle(title){
            return title.replaceAll('_', ' ').toUpperCase();
        },
        getTranslations() {
            return new Promise((resolve) => {
                return axios
                    .get(API_BASE_URL + "/translations", {
                        headers: this.header,
                    })
                    .then((res) => {
                        this.translations = res.data.data;
                        if(this.translations && this.translations.length > 0){
                            this.selected_index = 0; // Select first index
                        }
                        resolve(res.data.data);
                    });
            });
        },
        // Save Translation
        onSubmit() {
                let data = this.translations.map((element) => {
                    let newData = {
                        id: element.id,
                        key: element.key,
                    };
                    this.company_languages.forEach(language => {
                        newData[language] = element[language];
                    });
                    return newData;
                });
                axios
                    .post(API_BASE_URL + "/translations", data, {
                        headers: this.header,
                    })
                    .then((response) => {
                        this.$toast.success(
                            this.$t("company_settings.data_saved_successfully")
                        );
                    })
                    .catch(() => {
                        this.$toast.error(
                            this.$t("company_settings.save_failed")
                        );
                    })
                    .finally(() => {});
        },
    },
};
</script>

<style scoped>
.selected_customer {
    background-color: rgb(230, 230, 230) !important;
}
.selected_item_header {
    color: black;
    padding-bottom: 14px;
}
</style>
